/**
 * Copyright © 2019 Elastic Path Software Inc. All rights reserved.
 *
 * This is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This software is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with this license. If not, see
 *
 *     https://www.gnu.org/licenses/
 *
 *
 */

import packageJson from "../../package.json";

/* eslint-disable camelcase */
export interface IEpConfig {
  cortexApi: {
    path: string;
    scope: string;
    pathForProxy: string;
    reqTimeout: string;
    overrideHost?: string;
  };
  DaikinApi: {
    baseUri: string;
    path: string;
    query: string;
    registerPath: string;
  };
  brXM: {
    origin: string;
    siteOrigin: string;
    cmsOrigin: string;
    contextPath: string;
    defaultChannel: string;
    channels: Array<{ value: string; language: string }>;
  };
  supportedLocales: Array<{
    value: string;
    name: string;
  }>;
  defaultLocaleValue: string;
  supportedCurrencies: Array<{
    value: string;
    name: string;
  }>;
  pageTitle: string;
  enableBulkCart: boolean;
  defaultCurrencyValue: string;
  skuImagesUrl: string;
  siteImagesUrl: string;
  enableOfflineMode: boolean;
  b2b: {
    enable: boolean;
    accounts: boolean;
    authServiceAPI: {
      path: string;
      pathForProxy: string;
      reqTimeout: string;
      overrideHost?: string;
    };
    openId: {
      enable: true;
      callbackUrl: string;
      sessionExpiredUrl: string;
      partialLogoutUrl: string;
    };
    keycloak: {
      callbackUrl: string;
      loginRedirectUrl: string;
      logoutRedirectUrl: string;
      client_id: string;
    };
  };
  gaTrackingId: string;
  gtmId: string;
  arKit: {
    enable: boolean;
    skuArImagesUrl: string;
  };
  indi: {
    enable: boolean;
    carousel: {
      apikey: string;
      id: string;
      size: string;
      theme: string;
      round_corners: boolean;
      show_title: boolean;
      show_views: boolean;
      show_likes: boolean;
      show_buzz: boolean;
      animate: boolean;
    };
    productReview: {
      apikey: string;
      id: string;
      reviews_title: string;
      size: string;
      theme: string;
      round_corners: boolean;
      show_title: boolean;
      show_views: boolean;
      show_likes: boolean;
      show_buzz: boolean;
      animate: boolean;
      submit_link_label: string;
      allowanon: boolean;
      return_url: string;
      show_submit_thumbnail: boolean;
      submit_prompt_title: string;
      submit_prompt_desc: string;
      submit_button_url?: string;
      thumbnail_url?: string;
    };
    brandAmbassador: {
      title: string;
      description: string;
      submit_button_text: string;
      submit_button_url: string;
      thumbnail_url: string;
      return_url: string;
    };
  };
  bloomreachSearch: {
    enable: boolean;
    config: {
      search: {
        baseUri: string;
        accountId: string;
        authKey: string;
        domainKey: string;
        requestId: string;
        url: string;
        refUrl: string;
        requestType: string;
        fieldList: string;
      };
      suggest: {
        baseUri: string;
        accountId: string;
        authKey: string;
        domainKey: string;
        requestId: string;
        url: string;
        refUrl: string;
        requestType: string;
        suggestionDropdownLimit: string;
      };
      mlt: {
        baseUri: string;
        accountId: string;
        authKey: string;
        domainKey: string;
        requestId: string;
        url: string;
        refUrl: string;
        requestType: string;
        rows: string;
        start: string;
        fieldList: string;
      };
    };
  };
  bloomreachWidget: {
    config: {
      recommendedProductsCart: {
        baseUri: string;
        accountId: string;
        domainKey: string;
        widgetId: string;
        rows: string;
        fieldList: string;
      };
      recommendedProductsPDP: {
        baseUri: string;
        accountId: string;
        domainKey: string;
        widgetId: string;
        rows: string;
        fieldList: string;
      };
      similarProducts: {
        baseUri: string;
        accountId: string;
        domainKey: string;
        widgetId: string;
        widgetType: string;
        rows: string;
        productsDisplayed: string;
        fieldList: string;
      };
    };
  };
  facebook: {
    enable: boolean;
    pageId: string;
    applicationId: string;
  };
  GDPR: {
    enable: boolean;
    dataPolicySegments: string;
  };
  formatQueryParameter: {
    standardlinks: boolean;
    noself: boolean;
    nodatalinks: boolean;
  };
  creditCardTokenization: {
    enable: boolean;
    lambdaURI: string;
    overrideCustomReceiptURI: string;
    overrideCustomCancelURI: string;
  };
  "mlt-carousel": {
    mobile: number;
    tablet: number;
    carouselId: string;
    mobileItems: number;
    tabletItems: number;
    desktopItems: number;
  };
  "cart-recommended-products-carousel": {
    mobile: number;
    tablet: number;
    carouselId: string;
    mobileItems: number;
    tabletItems: number;
    desktopItems: number;
  };
  supportEmail: string;
  daikinConnectDealerAppLinks: {
    ios: string;
    android: string;
    officialApp: string;
    enabled: boolean;
  };
  ahriURL: string;
  displayAhriLink: boolean;
  browserLinks: Array<{ name: string; value: string }>;
  segmentWriteKey: string;
  ZENDESK_KEY: string;
  checkAvailability: boolean;
  infoFinderPlusURL: string;
  onlineBillingWebsiteUS: string;
  onlineBillingWebsiteCA: string;
  googleMapApiKey: string;
  pageMetadata: {
    title: string;
    description: string;
    canonicalURL: string;
  };
  footer: string;
  pdpComponentsOrder: Array<string>;
  statementPageDisplay: boolean;
  creditInformationPageDisplay: boolean;
  branchDropDownLocation: string;
  showBranchName: boolean;
  chatEnabled: boolean;
  entitlementCheck: boolean;
  POAlwaysRequired: boolean;
  calculatePrice: boolean;
  accountVerificationEmail: string;
  showPrintInvoice: boolean;
  enablePromotion: boolean;
  showOnlineBillPay: boolean;
  showRegistration: boolean;
  showCompanyName: boolean;
  showCustomerNumber: boolean;
  showManageUsers: boolean;
  orderHistoryShowJob: boolean;
  orderHistorySearchBySKU: boolean;
  useEPOrderNumber: boolean;
  orderHistoryQTYShipped: boolean;
  showBackOrderedQuantity: boolean;
  showShipComplete: boolean;
  showUserNotifications: boolean;
  productRegistrationURL: boolean;
  partnerLinkURL: boolean;
  invoicePageDisplay: boolean;
  showMarketingCommunications: boolean;
  missingImagePlaceholderUrl: string;
  useZendeskZopimChat: boolean;
  orderHistoryShowTotal: boolean;
  warrantyExpressURL: string;
  orderHistorySorting: boolean;
  useAlternativeMobileMatchUps: boolean;
  useMotiliEntitledBranchesService: boolean;
  displayPriceOnPickedTickets: boolean;
  showDealerFirstRewardLink: boolean;
  showOrderHistoryDetailsPayment: boolean;
  canUpdateDefaultBranch: boolean;
  showClientInformation: boolean;
  partsFinderPageDisplay: boolean;
  contractOrderPageDisplay: boolean;
  systemBuilderPageDisplay: boolean;
  showVendorOrderNumber: boolean;
  connectServicesToken: string;
  showRecentSearches: boolean;
  orderHistoryCSVExport: boolean;
  showRecentes: boolean;
  showRecommendedProducts: boolean;
  orderHistoryShowBranchNumber: boolean;
  sortByAvailability: boolean;
  showPhoneNumberPopUp: boolean;
  showDocumentsTab: boolean;
  documentsLibraryPageDisplay: boolean;
  showHomeBranchWarning: boolean;
  oneTrustDataDomainScript: string;
  environment: string;
  siteUrl: string;
  hideCreditCardPayment: boolean;
  showQuantitiesInLabels: boolean;
  EPOrderEnvPrefix: string;
  showStandardOrders: boolean;
  showDistributionCenter: boolean;
  abcoPolicyPath: string;
  daikinLogoSrc: string;
  abcoLogoScr: string;
  stevensLogoSrc: string;
  usgLogoSrc: string;
}
/* eslint-enable camelcase */

interface IConfig {
  config: IEpConfig | any;
  intl: any;
  DaikinApi: any;
}

let config: IConfig = {
  config: {},
  intl: { get: str => str },
  DaikinApi: {}
};

export function init(_config: IConfig) {
  return new Promise(resolve => {
    config = Object.freeze(_config);
    resolve({ version: packageJson.version });
  });
}

export function getConfig(): IConfig {
  return config;
}
