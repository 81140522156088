import React, { useContext } from "react";
import { useHistory } from "react-router";
import intl from "react-intl-universal";
import { MainContext } from "@elasticpath/ref-store/src/contexts/MainContext";
import { formatAhriLink } from "../../../app/src/utils/helpers";

import "./appheaderCTA.less";

interface AppHeaderCTAProps {
  isMobile?: boolean;
  isMobileMin?: boolean;
}

const AppHeaderCTA: React.FC<AppHeaderCTAProps> = props => {
  const { isMobile, isMobileMin } = props;
  const history = useHistory();
  const context = useContext<{
    cart: any;
    account: any;
  }>(MainContext);

  const {
    account: {
      accountDetails: { membership, customerNumber }
    },
    cart: {
      cartDetails: { defaultCart }
    }
  } = context;

  return (
    <div className="header-cta-container">
      <button
        type="button"
        className={`${
          isMobile ? "header-mobile-link" : "dast-btn-outlined-nav"
        }`}
        data-fullstory-id="nav-partsFinder-button"
        onClick={() => history.push("/partsFinder")}
      >
        {isMobileMin
          ? intl.get("find-a-part-sm-mobile")
          : intl.get("find-a-part")}
      </button>
      <button
        type="button"
        className={`${
          isMobile ? "header-mobile-link" : "dast-btn-outlined-nav"
        }`}
        onClick={() => formatAhriLink(membership, customerNumber, defaultCart)}
        data-fullstory-id="nav-systemBuilder"
      >
        {intl.get("build-a-system")}
      </button>
    </div>
  );
};

export default AppHeaderCTA;
