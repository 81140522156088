import React, { useContext, useEffect, useState } from "react";
import intl from "react-intl-universal";
import Modal from "react-responsive-modal";
import { AddressContainer, getConfig } from "@zilker/store-components";
import ProductItem from "./productItem";
import {
  groupCartItems,
  isSpecialAirPurifier
} from "../../../app/src/utils/helpers";
import { generateImageUrl } from "../../../app/src/utils/mappings/productDetails";
import { ReactComponent as DropdownOpenIcon } from "../../../app/src/images/icons/dropdown-open-icon.svg";
import { ReactComponent as DropdownCloseIcon } from "../../../app/src/images/icons/dropdown-close-icon.svg";
import { ReactComponent as PlusIcon } from "../../../app/src/images/icons/plus-icon.svg";
import { MainContext } from "../../../app/src/contexts/MainContext";
import BranchDropdownComponent from "../BranchDropdownComponent/BranchDropdownComponent";

import "./fulfillmentsection.main.less";
import "../../../app/src/theme/sharedClasses.less";

interface FulfillmentSectionProps {
  deliveryLoading: boolean;
  newAddressUri: string;
  orderData: any;
  editAddress: (...args: any[]) => any;
  handleChange: (...args: any[]) => any;
  isAddressAssociatedWithJob: (...args: any[]) => any;
  controlJobAddressModal: (...args: any[]) => any;
  newAddress: (...args: any[]) => any;
  backOrdered: boolean;
}

enum JobAddressActions {
  ADD_NEW = "new"
}

const FulfillmentSection: React.FC<FulfillmentSectionProps> = props => {
  const {
    deliveryLoading,
    newAddressUri,
    orderData,
    editAddress,
    handleChange,
    isAddressAssociatedWithJob,
    controlJobAddressModal,
    newAddress,
    backOrdered
  } = props;

  const [pickupTab, setPickupTab] = useState<boolean>(false);
  const [deliveryTab, setDeliveryTab] = useState<boolean>(false);
  const [pickupItems, setPickupItems] = useState([]);
  const [deliveryItems, setDeliveryItems] = useState([]);
  const [airPurifierItems, setAirPurifierItems] = useState([]);
  const [branchesNums, setBranchesNums] = useState([]);
  const [openAddressesModal, setOpenAddressesModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [backOrderedItems, setBackOrderedItems] = useState(false);

  const context = useContext<{
    cart: any;
    user: any;
    branches: any;
    account: any;
    auth: any;
  }>(MainContext);
  const { cart, branches } = context;

  const {
    selectedShippingOption,
    selectedShippingAddress,
    cartDetails: {
      defaultCart: { items, selectedBranch }
    }
  } = cart;

  const { config } = getConfig();

  const handleShippingAddressChange = shippingAddress => {
    const { setPendingAddress, setShippingAddressSelected } = cart;
    if (isAddressAssociatedWithJob()) {
      controlJobAddressModal("change");
      // When user opens modal, we need to save the selected address as pending address in state.
      setPendingAddress(shippingAddress);
    } else if (shippingAddress && !shippingAddress["phone-number"]) {
      // if address is missing phone number open edit address modal with warning message
      editAddress(shippingAddress.self.uri);
    } else {
      setShippingAddressSelected(shippingAddress);
    }
  };

  const renderShippingAddress = (renderedAddresses, openInModal) => {
    if (renderedAddresses && renderedAddresses.length) {
      return renderedAddresses.map((shippingAddress, index) => {
        const { name, address, checked, messages } = shippingAddress;
        const jobName = shippingAddress["job-name"];
        const jobNumber = shippingAddress["job-number"];

        const shippingKey = shippingAddress.address["street-address"]
          .replace(/\s+/g, "")
          .concat("_", index);

        /**
         * Check the latest address that has been edited or added and validate if it has warning message in the
         * messages array. Display warning below the address if the EP returned warning message for the invalid address.
         * If the address has been edited and warning disappeared, don;t show the message below the address.
         */
        const displayWarningMessage = Boolean(
          newAddressUri &&
            shippingAddress.uri === newAddressUri &&
            messages.length
        );

        const selectedtDiv = selectedShippingAddress
          ? selectedShippingAddress.uri === shippingAddress.uri
          : checked;

        return (
          <div
            className={`form-group ${selectedtDiv ? "selected" : ""} ${
              openInModal ? "wide-label" : ""
            }`}
            key={shippingKey}
          >
            <div className="form-check">
              <input
                type="radio"
                name={shippingKey}
                id={shippingKey}
                aria-label={`shipping option ${Object.values(
                  shippingAddress.address
                )}`}
                className="form-check-input"
                checked={
                  selectedShippingAddress
                    ? selectedShippingAddress.uri === shippingAddress.uri
                    : checked
                }
                onChange={() => handleShippingAddressChange(shippingAddress)}
              />
              <label className="form-check-label" htmlFor={shippingKey}>
                <AddressContainer name={name} address={address} />
                {displayWarningMessage && (
                  <div className="new-address-warning">
                    <p>{intl.get("new-address-warning")}</p>
                  </div>
                )}
                {jobName || jobNumber ? (
                  <div className="checkout-address-job-info">
                    {jobName && (
                      <div>
                        <span>{`${intl.get("job-name")}: `}</span>
                        <span>{jobName}</span>
                      </div>
                    )}
                    {jobNumber && (
                      <div>
                        <span>{`${intl.get("job-number")}: `}</span>
                        <span>{jobNumber}</span>
                      </div>
                    )}
                  </div>
                ) : null}
              </label>
            </div>
            {/** Allow editing on all addresses now. But for the default and job linking account shipping addresses only allow First and Last name */}
            <button
              aria-label={intl.get("edit")}
              className={`edit-btn ${
                openInModal ? "dast-btn dast-btn-primary" : "dast-link"
              }`}
              type="button"
              onClick={() => {
                editAddress(shippingAddress.self.uri);
              }}
            >
              {intl.get("edit")}
            </button>
          </div>
        );
      });
    }
    return (
      <div>
        <p>{intl.get("no-shipping-address-message")}</p>
      </div>
    );
  };

  const handleShipCompleteChange = () => {
    const { setOrderInformationData, orderInformation } = cart;
    setOrderInformationData({
      ...orderInformation,
      "ship-complete": !orderInformation["ship-complete"]
    });
  };

  const showTab = e => {
    let tabName;
    const {
      target: { tagName }
    } = e;
    if (tagName === "BUTTON") {
      const {
        target: { name }
      } = e;
      tabName = name;
    } else {
      const {
        target: {
          parentElement: { name }
        }
      } = e;
      tabName = name;
    }

    switch (tabName) {
      case "pickup":
        setPickupTab(!pickupTab);
        break;
      case "delivery":
        setDeliveryTab(!deliveryTab);
        break;
      default:
    }
  };

  const findBranchName = branch => {
    const { branchesList, airPurifierBranch } = branches;
    const {
      branchNumber: airPurifierBranchNumber,
      branchName: airPurifierBranchName
    } = airPurifierBranch;

    const foundBranch = branchesList.find(
      branchInList => branchInList.branchNumber === branch
    );

    return branch === airPurifierBranchNumber
      ? airPurifierBranchName
      : foundBranch && foundBranch.branchName;
  };

  const renderBranchLabel = (fulfillmentType, fulfillmentBranch) => {
    if (fulfillmentType === "delivery") {
      return (
        <>
          <BranchDropdownComponent
            selectedBranch={selectedBranch && selectedBranch.code}
            isCheckout
          />
          <span>{intl.get("delivery-branch-messaging")}</span>
        </>
      );
    }
    if (fulfillmentType === "pickup") {
      return findBranchName(fulfillmentBranch);
    }
    return (
      <>
        <div>
          <span className="air-purifier-branch-label">
            {intl.get("delivery-branch-label")}:{" "}
          </span>
          {findBranchName(fulfillmentBranch)}
        </div>
        <span>{intl.get("air-purifier-branch-messaging")}</span>
      </>
    );
  };

  const renderFulfillmentSection = (
    fulfillmentItems,
    fulfillmentBranch,
    fulfillmentType,
    buttonText
  ) => {
    return (
      <>
        <div
          className={`branch-section ${fulfillmentType.toLowerCase() !==
            "pickup" && "branch-section-delivery"}`}
        >
          {renderBranchLabel(fulfillmentType, fulfillmentBranch)}
        </div>
        <div className="content-header">
          <div className="header-item">{intl.get("product")}</div>
          <div className="header-item">{intl.get("price")}</div>
          <div className="header-item">{intl.get("qty")}</div>
          <div className="header-item"> </div>
        </div>
        <ul className="product-features-list">
          {Object.keys(fulfillmentItems)
            .reverse()
            .map(group => {
              const groupedItems = fulfillmentItems[group].map(groupItem => {
                const addedGroupItemInfo = {
                  ...groupItem,
                  name: groupItem._item[0]._definition[0]["display-name"],
                  brand: groupItem._item[0]._definition[0].details.find(
                    detail => detail.name === "brand"
                  ).value,
                  priceNumber: groupItem._price[0]["purchase-price"][0].amount,
                  category: groupItem._item[0]._definition[0].details.find(
                    detail => detail.name.toLowerCase() === "product_category"
                  ).value
                };
                return addedGroupItemInfo;
              });
              const renderedItems =
                fulfillmentType === "pickup"
                  ? fulfillmentItems[group].filter(
                      item => item["branch-number"] === fulfillmentBranch
                    )
                  : fulfillmentItems[group];

              return renderedItems.map((item, itemIndex) => {
                const productImage = generateImageUrl(
                  item._item[0]._definition[0].details
                );
                const displayName =
                  item._item[0]._definition[0]["display-name"];
                return (
                  <ProductItem
                    key={`${
                      item._item[0]._code[0].code
                    }_${fulfillmentType} ${group || ""}`}
                    sku={item._item[0]._code[0].code}
                    description={item._item[0]._definition[0]["display-name"]}
                    price={item._price[0]["list-price"][0].amount}
                    quantity={item.quantity}
                    buttonText={buttonText}
                    uri={item.self.uri}
                    productImage={productImage}
                    displayName={displayName}
                    setIsLoading={setIsLoading}
                    isLoading={isLoading}
                    groupId={group}
                    first={itemIndex === 0}
                    groupedItems={groupedItems}
                    brand={
                      item._item[0]._definition[0].details.find(
                        itemDetail => itemDetail.name === "brand"
                      ).value
                    }
                  />
                );
              });
            })}
        </ul>
      </>
    );
  };

  const renderFulfillmentAccordion = () => {
    const { airPurifierBranch } = branches;
    const { branchNumber: airPurifierBranchNumber } = airPurifierBranch;

    return (
      <div className="checkout-accordion">
        <ul className="nav checkout-nav-accordion">
          {pickupItems && Object.keys(pickupItems).length ? (
            <li className="checkout-nav-item">
              <button
                type="button"
                className="checkout-nav-link"
                name="pickup"
                onClick={showTab}
              >
                <h3>{intl.get("pick-up-items")}</h3>
                {pickupTab ? <DropdownCloseIcon /> : <DropdownOpenIcon />}
              </button>
              {pickupTab &&
                branchesNums.length &&
                branchesNums.map(branch => {
                  return (
                    <div className="checkout-tab-content" key={branch}>
                      {renderFulfillmentSection(
                        pickupItems,
                        branch,
                        "pickup",
                        intl.get("ship-instead")
                      )}
                    </div>
                  );
                })}
            </li>
          ) : null}
          {(deliveryItems && Object.keys(deliveryItems).length) ||
          (airPurifierItems && Object.keys(airPurifierItems).length) ? (
            <li className="checkout-nav-item">
              <button
                type="button"
                className="checkout-nav-link"
                name="delivery"
                onClick={showTab}
              >
                <h3>{intl.get("delivery-items")}</h3>
                {deliveryTab ? <DropdownCloseIcon /> : <DropdownOpenIcon />}
              </button>
              {deliveryTab ? (
                <div className="checkout-tab-content delivery">
                  {deliveryItems && Object.keys(deliveryItems).length
                    ? renderFulfillmentSection(
                        deliveryItems,
                        selectedBranch && selectedBranch.code,
                        "delivery",
                        intl.get("pick-up-instead")
                      )
                    : null}
                  {airPurifierItems && Object.keys(airPurifierItems).length
                    ? renderFulfillmentSection(
                        airPurifierItems,
                        airPurifierBranchNumber,
                        "airPurifierDelivery",
                        intl.get("delivery-only")
                      )
                    : null}
                </div>
              ) : null}
            </li>
          ) : null}
        </ul>
      </div>
    );
  };

  const renderShippingOptionsSelector = () => {
    const {
      account: {
        accountDetails: { customerRoles }
      }
    } = context;
    const { selectedDeliveryMethodError, orderInformation } = cart;

    const BRANCHES_VIRTUAL = intl.get("virtual-branches");
    const isVirtualBranchUser =
      customerRoles && customerRoles.includes(BRANCHES_VIRTUAL);

    /**
     * The user can still have shipping address in his address book.
     * But since those addresses are not associated with the order, the ._order[0]._deliveries is not sent.
     */
    // const deliveries = orderData._order[0]._deliveries;
    // if (deliveries && deliveries[0]._element[0]._destinationinfo) {
    return (
      <div>
        <h3
          className={`section-subtitle ${
            selectedDeliveryMethodError ? "validation-error-border" : ""
          }`}
        >
          <span className="star">*</span>
          {intl.get("fulfillment-method-label")}
        </h3>
        <p className="section-subtitle-error">{selectedDeliveryMethodError}</p>
        {config.showShipComplete && backOrderedItems && (
          <div className="ship-complete-option">
            <label
              className="ship-complete-label"
              htmlFor="ship-complete"
              id="ship-complete-label"
              onClick={handleShipCompleteChange}
              onKeyDown={handleShipCompleteChange}
            >
              <input type="checkbox" name="shipComplete" id="checkbox" />
              <span
                className={`ship-complete-checkbox ${
                  orderInformation["ship-complete"]
                    ? "show-checkmark"
                    : "hide-checkmark"
                }`}
                onClick={handleShipCompleteChange}
                onKeyDown={handleShipCompleteChange}
                role="checkbox"
                aria-checked="false"
                tabIndex={0}
                aria-labelledby="ship-complete-label"
                id="ship-complete"
              />
              {intl.get("ship-complete")}
            </label>
            <p className="ship-complete-instructions">
              *
              {isVirtualBranchUser
                ? intl.get("ship-complete-instructions-virtual-branch")
                : intl.get("ship-complete-instructions")}
            </p>
          </div>
        )}
        {renderFulfillmentAccordion()}
      </div>
    );
  };

  const handleCloseAddressesModal = () => {
    setOpenAddressesModal(false);
  };

  const renderAddressBookModal = () => {
    const { shippingAddresses } = cart;

    return (
      <Modal open={openAddressesModal} onClose={handleCloseAddressesModal}>
        <div className="modal-lg new-address-modal">
          <div className="modal-content">
            <div className="modal-header">
              <h2 className="modal-title">{intl.get("address-book")}</h2>
            </div>
            <div className="modal-body">
              {renderShippingAddress(shippingAddresses, true)}
            </div>
          </div>
        </div>
      </Modal>
    );
  };

  const renderShippingAddressSelector = () => {
    const deliveries = orderData._order[0]._deliveries;
    const { messages } = orderData._order[0];
    const needShipmentDetails = messages.find(
      message => message.id === "need.shipping.address"
    );
    const { shippingAddresses } = cart;
    const renderedShippingAddresses =
      shippingAddresses &&
      shippingAddresses.length &&
      shippingAddresses.slice(0, 5);

    /**
     * The user can still have shipping address in his address book.
     * But since those addresses are not associated with the order, the ._order[0]._deliveries is not sent.
     */

    if (needShipmentDetails || deliveries) {
      return (
        <>
          {(deliveryItems && Object.keys(deliveryItems).length) ||
          (airPurifierItems && Object.keys(airPurifierItems).length) ? (
            <>
              <h3>{intl.get("fulfillment-address")}</h3>
              <div className="add-new-address-button">
                {renderShippingAddress(renderedShippingAddresses, false)}
                <div className="form-group">
                  <button
                    className="add-address-btn"
                    aria-label={intl.get("add-new-address")}
                    type="button"
                    onClick={() => {
                      if (isAddressAssociatedWithJob()) {
                        controlJobAddressModal(JobAddressActions.ADD_NEW);
                      } else {
                        newAddress();
                      }
                    }}
                  >
                    {intl.get("add-new-address")}
                    <PlusIcon />
                  </button>
                </div>
              </div>
              <button
                type="button"
                className="view-address-book-btn dast-link"
                onClick={() => setOpenAddressesModal(true)}
              >
                {intl.get("view-address-book")}
              </button>
            </>
          ) : null}
        </>
      );
    }

    return null;
  };

  useEffect(() => {
    const optionChanged =
      selectedShippingOption && selectedShippingOption.selectaction;
    if (optionChanged) {
      handleChange(selectedShippingOption.selectaction);
    }
  }, [selectedShippingOption]);

  useEffect(() => {
    setBackOrderedItems(!backOrderedItems);
  }, [backOrdered]);

  useEffect(() => {
    const addressChanged =
      selectedShippingAddress && selectedShippingAddress.selectaction;
    if (addressChanged) {
      handleChange(selectedShippingAddress.selectaction);
    }
  }, [selectedShippingAddress]);

  useEffect(() => {
    const {
      cartDetails: { defaultCart }
    } = cart;
    const { airPurifierBranch } = branches;
    const { branchNumber: airPurifierBranchNumber } = airPurifierBranch;

    if (defaultCart) {
      const pickupItemsArr = items.filter(
        item => item["shipping-method"] === "pickup"
      );

      const pickupBranchNumbers = pickupItemsArr.map(
        item => item["branch-number"]
      );
      const filteredPickupBranhces = pickupBranchNumbers.filter(
        (value, index, array) => array.indexOf(value) === index
      );

      setBranchesNums(filteredPickupBranhces);

      const deliveryItemsArr = items.filter(item => {
        if (
          !isSpecialAirPurifier(item._item[0]._code[0].code) &&
          item["shipping-method"] === "delivery"
        ) {
          return true;
        }
        return false;
      });
      const airPurifierItemsArr = items.filter(item => {
        if (isSpecialAirPurifier(item._item[0]._code[0].code)) {
          return true;
        }
        return false;
      });

      const groupedDeliveryItems = groupCartItems(deliveryItemsArr);
      const groupedAirPurifierItems = groupCartItems(airPurifierItemsArr);
      const groupedPickupItems = groupCartItems(pickupItemsArr);

      setPickupItems(groupedPickupItems);
      setDeliveryItems(groupedDeliveryItems);
      setAirPurifierItems(groupedAirPurifierItems);
      setIsLoading(false);
    }
  }, [items]);

  return (
    <>
      <h4 className="section-title">{intl.get("fulfillment")}</h4>
      {openAddressesModal && renderAddressBookModal()}
      <div className="row">
        <div className="col-12 col-md-12">
          {renderShippingOptionsSelector()}
        </div>
      </div>
      <div className="row">
        {deliveryLoading ? (
          <div className="loader" />
        ) : (
          <div className="col-12 col-md-12">
            {renderShippingAddressSelector()}
          </div>
        )}
      </div>
    </>
  );
};

export default FulfillmentSection;
