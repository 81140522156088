import React, { useContext } from "react";
import {
  BrManageMenuButton,
  BrComponentContext,
  BrPageContext
} from "@bloomreach/react-sdk";
import { ImageSet, Page } from "@bloomreach/spa-sdk";
import "../appfooter.main.less";
import SiteLogos from "../../SiteLogosComponent/SiteLogos";

const AppFooterLogo = () => {
  const component = useContext(BrComponentContext);
  const page = useContext(BrPageContext);

  const getFooterLogoNestedComponent = () => {
    const containerImpl = component.getChildren()[0];
    const containerItemImpl = containerImpl.getChildren()[0];
    return containerItemImpl;
  };

  const childContainer = getFooterLogoNestedComponent();

  const { document: documentRef } = childContainer.getModels();
  const content = documentRef && page.getContent(documentRef);
  const {
    body,
    image: logo1Obj,
    logo2: logo2Obj,
    logo3: logo3Obj,
    logo4: logo4Obj
  } = content.getData();

  const logo1 = logo1Obj && page.getContent<ImageSet>(logo1Obj);
  const logo2 = logo2Obj && page.getContent<ImageSet>(logo2Obj);
  const logo3 = logo3Obj && page.getContent<ImageSet>(logo3Obj);
  const logo4 = logo4Obj && page.getContent<ImageSet>(logo4Obj);

  const logos = [logo1, logo2, logo3, logo4].map(logo =>
    logo ? logo.getOriginal().getUrl() : null
  );

  return <SiteLogos logos={logos} content={body.value} />;
};

export default AppFooterLogo;
