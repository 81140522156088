/* eslint-disable react/no-unused-state */
import React from "react";
import Modal from "react-responsive-modal";
import intl from "react-intl-universal";
import { NavLink } from "react-router-dom";
import {
  handleCustomException,
  formatInventoryAvailability,
  formatAvailabilityLabel,
  formatAlternateBranchesInventory,
  formatRealTimeDGAInventory,
  InventoryAvailabilityInterface
} from "@elasticpath/ref-store/src/utils/helpers";
import { getConfig, IEpConfig } from "../utils/ConfigProvider";
import { MainContext } from "../../../app/src/contexts/MainContext";
import { getProductPrice } from "../../../app/src/utils/mappings/productDetails";
import * as ConnectService from "../../../app/src/services/connectServices";
import { checkEntitlementGQL } from "../../../app/src/services/connectGQLservices";
import { DebugMessage } from "../MessageContainer/messagecontainer";
import AlternateBranchList from "../AlternateBranchList/AlternateBranchList";

import "./AuthProductDetailsComponent.less";
import { productViewed } from "../utils/Segment";

// Types
interface AuthProductDetailsProps {
  encodedProductSku: string;
  productSku: string;
  productData: any;
  itemPriceDetails: any;
  handleGenericException: (...args: any) => any;
  populatePrice: (...args) => any;
  productQuantity?: string;
  history: any;
  handlePriceCatalogException: (...args: any) => any;
}
interface AuthProductDetailsState {
  priceDetails: any;
  responseMessage: DebugMessage;
  redirect: boolean;
  skuEntitled: boolean;
  openModal: boolean;
  itemQuantity: number;
  branchAvailability: any;
  regionAvailability: any;
  branches: any;
  jobNumber: any;
  currentBranch: any;
  unitOfMeasure: number;
  validQuantity: boolean;
  noAvailableBranches: boolean;
  entitlementCheckDone: boolean;
  availabilityCheckDone: boolean;
  memberBranchInventory: Array<{
    sku: string;
    branchNumber: number;
    quantityAvailable: number;
  }>;
}

let Config: IEpConfig;

class AuthProductDetailsComponent extends React.Component<
  AuthProductDetailsProps,
  AuthProductDetailsState
> {
  static contextType = MainContext;

  _isMounted = false;

  constructor(props) {
    super(props);
    Config = getConfig().config;
    this.state = {
      priceDetails: null,
      responseMessage: null,
      redirect: false,
      skuEntitled: true,
      openModal: false,
      itemQuantity: 1,
      branchAvailability: null,
      regionAvailability: null,
      branches: null,
      jobNumber: null,
      currentBranch: null,
      unitOfMeasure: 1,
      validQuantity: true,
      noAvailableBranches: false,
      entitlementCheckDone: false,
      availabilityCheckDone: false,
      memberBranchInventory: []
    };

    this.fetchPrice = this.fetchPrice.bind(this);
    this.validateEntitlement = this.validateEntitlement.bind(this);
    this.validateAvailability = this.validateAvailability.bind(this);
    this.populateitemQuantity = this.populateitemQuantity.bind(this);
    this.openAlternateBranchesModal = this.openAlternateBranchesModal.bind(
      this
    );
    this.closeAlternateBranchesModal = this.closeAlternateBranchesModal.bind(
      this
    );
  }

  componentDidMount() {
    const {
      encodedProductSku,
      productSku,
      productQuantity,
      productData
    } = this.props;

    const {
      cart: {
        cartDetails: { defaultCart }
      }
    } = this.context;
    const selectedBranch = defaultCart ? defaultCart.selectedBranch : null;
    const jobNumber = defaultCart ? defaultCart.jobNumber : null;

    this._isMounted = true;

    this.setState({ currentBranch: selectedBranch, jobNumber });

    const { pathname } = window.location;
    if (pathname.includes("itemdetail")) {
      if (Config.calculatePrice) {
        this.fetchPrice(productSku, productQuantity);
      } else {
        const categoryName = productData._parentcategory[0].name;
        const name = productData._definition[0]["display-name"];
        const id = productData._code[0].code;
        const brand = productData._definition[0].details.find(
          detail => detail.name === "brand"
        ).value;

        productViewed(name, id, null, brand, categoryName);
      }

      this.validateEntitlement(encodedProductSku);
      this.validateAvailability(encodedProductSku);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { encodedProductSku, productSku, productQuantity } = this.props;
    const { currentBranch } = prevState;
    const { entitlementCheckDone, availabilityCheckDone } = this.state;
    const { cart } = this.context;
    const {
      cartDetails: { defaultCart }
    } = cart;
    const jobNumber = defaultCart ? defaultCart.jobNumber : null;

    const { jobNumber: prevJobNumber } = prevState;

    this.trackBranchSelection(currentBranch);

    if (prevJobNumber && jobNumber && prevJobNumber !== jobNumber) {
      this.updateJobState(jobNumber, productSku, productQuantity);
    }
    if (
      encodedProductSku &&
      encodedProductSku !== prevProps.encodedProductSku
    ) {
      if (Config.calculatePrice) this.fetchPrice(productSku, productQuantity);
      this.validateEntitlement(encodedProductSku);
      this.validateAvailability(encodedProductSku);
    }
    if (!entitlementCheckDone) {
      this.validateEntitlement(encodedProductSku);
    }
    if (!availabilityCheckDone) {
      this.validateAvailability(encodedProductSku);
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  updateJobState(jobNumber, productSku, productQuantity) {
    this.setState({ jobNumber });
    if (Config.calculatePrice) this.fetchPrice(productSku, productQuantity);
  }

  /**
   * ## fetchPrice
   *
   * @param productId string - SKU of the product
   * @param productQuantity? string - quantity of the product (opt)
   *
   * @description Method that fetches the pricing details for the
   * product - based on its SKU code.
   */
  fetchPrice(productId: string, productQuantity?: string) {
    const { auth, cart, job, account } = this.context;
    const {
      accountDetails: { customerNumber }
    } = account;
    const { logout } = auth;

    const { populatePrice, handlePriceCatalogException, history } = this.props;

    const {
      cartDetails: { defaultCart }
    } = cart;
    const selectedBranch = defaultCart ? defaultCart.selectedBranch : null;
    const jobNumber = defaultCart ? defaultCart.jobNumber : null;

    if (auth.isLoggedIn && selectedBranch && customerNumber) {
      const priceRequestBody = {
        customerNumber,
        shipments: [
          {
            branchNumber: selectedBranch.code,
            customerPickup: false,
            items: [
              {
                sku: productId,
                quantity: 1
              }
            ]
          }
        ],
        jobNumber
      };

      if (defaultCart.jobNumber || job.persistedJobNumber) {
        priceRequestBody.jobNumber =
          defaultCart.jobNumber || job.persistedJobNumber;
      }

      getProductPrice(priceRequestBody, populatePrice, e => {
        const errorPath =
          "handlePriceCatalogException => productdisplayidem.main.tsx";

        handleCustomException(e, logout, history, errorPath);

        handlePriceCatalogException();
      });
    }
  }

  /**
   * ## validateEntitlement
   * @param sku string
   *
   * @description Validates if the logged in user is entitled for the SKU
   */
  async validateEntitlement(sku: string) {
    const { history } = this.props;

    const {
      cart: {
        cartDetails: { defaultCart }
      },
      account: {
        accountDetails: { customerNumber }
      },
      auth: { logout }
    } = this.context;

    if (Config.entitlementCheck && defaultCart) {
      try {
        const { data } = await checkEntitlementGQL(customerNumber, [sku]);
        if (this._isMounted && data && data.data && data.data.customer) {
          this.setState({
            entitlementCheckDone: true
          });
          if (!data.data.customer.productEntitlements) {
            this.setState({
              skuEntitled: !Config.entitlementCheck
            });
          } else {
            this.setState({
              skuEntitled: data.data.customer.productEntitlements[0].entitled
            });
          }
        }
      } catch (error) {
        if (this._isMounted) {
          this.setState({
            entitlementCheckDone: true
          });
        }
        const errorPath =
          "validateEntitlement => checkEntitlementGQL => AuthProductDetailsComponent.tsx";
        handleCustomException(error, logout, history, errorPath);
      }
    }
  }

  handleAvailabilityError(error) {
    const {
      cart: {
        cartDetails: { defaultCart }
      },
      auth: { logout }
    } = this.context;
    const { history } = this.props;

    const {
      cortexApi: { scope }
    } = Config;
    const { selectedBranch, clientId } = defaultCart;

    if (
      selectedBranch.vendor !== "GOODMAN" &&
      scope === "motili" &&
      this._isMounted
    ) {
      /* See DGE-3133. This is related only to Motili store, if the distributor branch
      is other than "GOODMAN".
    */
      this.setState({ noAvailableBranches: true });
    }
    if (this._isMounted) {
      this.setState({
        availabilityCheckDone: true
      });
    }
    const errorPath =
      "validateAvailability => getAvailability => AuthProductDetailsComponent.tsx";
    handleCustomException(error, logout, history, errorPath);
  }

  handleDGAInventory(
    sku: string,
    memberBranches,
    allBranchNumbers,
    alternateBranches,
    newSelectedBranch?: any
  ) {
    const { itemQuantity } = this.state;
    const {
      account: {
        accountDetails: { customerNumber, customerRoles }
      }
    } = this.context;
    const BRANCHES_VIRTUAL = intl.get("virtual-branches");
    const isVirtualBranchUser =
      customerRoles && customerRoles.includes(BRANCHES_VIRTUAL);

    ConnectService.getRealTimeInventoryDGA(
      customerNumber,
      [sku],
      isVirtualBranchUser ? memberBranches : allBranchNumbers
    )
      .then(inventoryResponse => {
        const inventoryAvailability: Array<
          InventoryAvailabilityInterface
        > = formatRealTimeDGAInventory(inventoryResponse.data, memberBranches);
        const memberBranchInventory = inventoryResponse.data.result.map(
          branch => {
            return {
              sku: branch.items[0].sku,
              branchNumber: branch.branchNumber,
              quantityAvailable: branch.items[0].quantityAvailable
            };
          }
        );
        const inventoryItem = inventoryAvailability.length
          ? inventoryAvailability[0]
          : {
              branchAvailability: 0,
              regionAvailability: 0,
              unitOfMeasure: 1
            };
        if (this._isMounted) {
          if (newSelectedBranch) {
            this.setState({
              branchAvailability: inventoryItem.branchAvailability,
              regionAvailability: inventoryItem.regionAvailability,
              branches: alternateBranches,
              currentBranch: newSelectedBranch,
              memberBranchInventory
            });
          } else {
            this.setState({
              noAvailableBranches: !alternateBranches,
              branchAvailability: inventoryItem.branchAvailability,
              regionAvailability: inventoryItem.regionAvailability,
              unitOfMeasure: inventoryItem.unitOfMeasure,
              validQuantity: itemQuantity % inventoryItem.unitOfMeasure === 0,
              branches: alternateBranches,
              availabilityCheckDone: true,
              memberBranchInventory
            });
          }
        }
      })
      .catch(error => {
        this.handleAvailabilityError(error);
      });
  }

  /**
   * ## validateAvailability
   * @param sku string
   *
   * @description Validates if item is available on current branch and in the region.
   */
  async validateAvailability(sku: string) {
    const { itemQuantity } = this.state;

    const {
      cart: {
        cartDetails: { defaultCart }
      },
      branches: { branchesList, findBranch },
      account: {
        accountDetails: { customerNumber, customerRoles }
      }
    } = this.context;
    const {
      cortexApi: { scope }
    } = Config;

    if (defaultCart && branchesList) {
      const { selectedBranch, clientId } = defaultCart;
      const { latitude, longitude, memberBranchNumbers } = findBranch(
        selectedBranch.code
      );
      const BRANCHES_VIRTUAL = intl.get("virtual-branches");
      const isVirtualBranchUser =
        customerRoles && customerRoles.includes(BRANCHES_VIRTUAL);

      const branchNumber = selectedBranch.code;
      let inventoryAvailability: Array<InventoryAvailabilityInterface>;
      ConnectService.getAvailability(
        customerNumber,
        selectedBranch.code,
        sku,
        selectedBranch.vendor,
        latitude,
        longitude,
        true,
        clientId
      )
        .then(({ data }) => {
          const alternateBranches = formatAlternateBranchesInventory(data);
          if (scope === "motili") {
            inventoryAvailability = formatInventoryAvailability(data);
            const inventoryItem = inventoryAvailability.length
              ? inventoryAvailability[0]
              : {
                  branchAvailability: 0,
                  regionAvailability: 0,
                  unitOfMeasure: 1
                };
            if (this._isMounted) {
              this.setState({
                noAvailableBranches: !alternateBranches,
                branchAvailability: inventoryItem.branchAvailability,
                regionAvailability: inventoryItem.regionAvailability,
                unitOfMeasure: inventoryItem.unitOfMeasure,
                validQuantity: itemQuantity % inventoryItem.unitOfMeasure === 0,
                branches: alternateBranches,
                availabilityCheckDone: true
              });
            }
          } else {
            const allBranchNumbers = data.allBranches.map(branch => {
              return branch.branchNumber;
            });
            let memberBranches = [branchNumber];
            if (isVirtualBranchUser) {
              // eslint-disable-next-line prefer-destructuring
              memberBranches = memberBranchNumbers;
            }
            this.handleDGAInventory(
              sku,
              memberBranches,
              allBranchNumbers,
              alternateBranches
            );
          }
        })
        .catch(error => {
          this.handleAvailabilityError(error);
        });
    }
  }

  /**
   * ## populateitemQuantity
   * @param quantity number
   *
   * @description Sets selected item quantity in the state.
   */
  populateitemQuantity(quantity: number): void {
    const { unitOfMeasure } = this.state;
    if (this._isMounted) {
      this.setState({
        itemQuantity: quantity,
        validQuantity: quantity % unitOfMeasure === 0
      });
    }
  }

  /**
   * @description Modal-related methods
   */

  openAlternateBranchesModal() {
    if (this._isMounted) {
      this.setState({ openModal: true });
    }
  }

  closeAlternateBranchesModal() {
    if (this._isMounted) {
      this.setState({ openModal: false });
    }
  }

  renderAlternateBranchesModal() {
    const {
      openModal,
      itemQuantity,
      branchAvailability,
      branches,
      memberBranchInventory,
      noAvailableBranches
    } = this.state;
    const { productSku, productData, history } = this.props;
    const productSkuCode = productSku;
    const productName =
      productData._item[0]._definition[0]["display-name"] || "";
    const productPrice = productData._price[0]["purchase-price"][0].amount;
    const productBrand = productData._item[0]._definition[0].details.find(
      itemDetail => itemDetail.name === "brand"
    ).value;

    const styles = {
      modal: {
        maxWidth: "1280px",
        width: "100%",
        height: "650px"
      }
    };

    return (
      <Modal
        open={openModal}
        onClose={this.closeAlternateBranchesModal}
        styles={{
          modal: styles.modal
        }}
      >
        <div>
          <AlternateBranchList
            product={{
              name: productName,
              sku: productSkuCode,
              price: productPrice,
              brand: productBrand
            }}
            qtyColumnHeader={intl.get("stock-status")}
            history={history}
            branches={branches}
          />
        </div>
      </Modal>
    );
  }

  /**
   * ## trackBranchSelection
   *
   * @param prevBranch any - this is branch object, that has property code:string
   *
   * @description When the user picks another branch from branch dropdown,
   * the availability data needs to be re-fetched, so the information about
   * number of available products gets updated on the page.
   * The current branch number from the state is compared with the value from the context,
   * whenever context gets updated. If the branch number has changed, the availability data is refetched.
   */
  trackBranchSelection(prevBranch: any): void {
    const {
      cart: {
        cartDetails: { defaultCart }
      },
      branches: { branchesList, findBranch },
      account: {
        accountDetails: { customerNumber, customerRoles }
      }
    } = this.context;
    const {
      cortexApi: { scope }
    } = Config;

    const { encodedProductSku, handleGenericException } = this.props;
    if (prevBranch && defaultCart && branchesList) {
      const { selectedBranch: newSelectedBranch, clientId } = defaultCart;
      const { latitude, longitude, memberBranchNumbers } = findBranch(
        newSelectedBranch.code
      );
      if (prevBranch.code !== newSelectedBranch.code) {
        let inventoryAvailability: Array<InventoryAvailabilityInterface>;
        ConnectService.getAvailability(
          customerNumber,
          newSelectedBranch.code,
          encodedProductSku,
          newSelectedBranch.vendor,
          latitude,
          longitude,
          clientId
        )
          .then(({ data }) => {
            if (scope === "motili") {
              inventoryAvailability = formatInventoryAvailability(data);
              const inventoryItem = inventoryAvailability.length
                ? inventoryAvailability[0]
                : {
                    branchAvailability: 0,
                    regionAvailability: 0,
                    unitOfMeasure: 1
                  };
              if (this._isMounted) {
                this.setState({
                  branchAvailability: inventoryItem.branchAvailability,
                  regionAvailability: inventoryItem.regionAvailability,
                  branches: formatAlternateBranchesInventory(data),
                  currentBranch: newSelectedBranch
                });
              }
            } else {
              const alternateBranches = formatAlternateBranchesInventory(data);
              const allBranchNumbers = data.allBranches.map(branch => {
                return branch.branchNumber;
              });
              const BRANCHES_VIRTUAL = intl.get("virtual-branches");
              const isVirtualBranchUser =
                customerRoles && customerRoles.includes(BRANCHES_VIRTUAL);
              let memberBranches = [newSelectedBranch.code];
              if (isVirtualBranchUser) {
                // eslint-disable-next-line prefer-destructuring
                memberBranches = memberBranchNumbers;
              }

              this.handleDGAInventory(
                encodedProductSku,
                memberBranches,
                allBranchNumbers,
                alternateBranches
              );
            }
          })
          .catch(error => {
            if (this._isMounted) {
              this.setState({ currentBranch: newSelectedBranch });
            }
            handleGenericException(error);
          });
      }
    }
  }

  render() {
    const {
      skuEntitled,
      branchAvailability,
      regionAvailability,
      validQuantity,
      unitOfMeasure
    } = this.state;
    const {
      productSku,
      productData,
      itemPriceDetails,
      handleGenericException
    } = this.props;

    const {
      account: {
        accountDetails: { customerRoles }
      }
    } = this.context;

    const BRANCHES_VIRTUAL = intl.get("virtual-branches");
    const isVirtualBranchUser =
      customerRoles && customerRoles.includes(BRANCHES_VIRTUAL);

    const { systemBuilderPageDisplay } = Config;

    return (
      <>
        <div>
          {this.renderAlternateBranchesModal()}

          <div className="d-flex h3 align-items-center">
            <span className="label">
              {formatAvailabilityLabel(branchAvailability, regionAvailability)}
            </span>
            {!isVirtualBranchUser && <span className="px-4">|</span>}

            {!isVirtualBranchUser && (
              <button
                className="d-flex align-items-center open-alternate-branches"
                type="button"
                onClick={this.openAlternateBranchesModal}
              >
                <div>
                  <i className="icon-home" />
                  {`  ${intl.get("check-availability-by-branch")}`}
                </div>
              </button>
            )}
          </div>
        </div>

        <div className="add-to-cart-container">
          {systemBuilderPageDisplay && (
            <p className="system-builder-message">
              {intl.get("need-complete-system")}
              <NavLink to="/systemBuilder" className="system-builder-link">
                {intl.get("system-builder")}
              </NavLink>
            </p>
          )}
        </div>
        {!validQuantity && (
          <p className="invalid-quantity-message">
            {intl.get("unit-of-measure-error-message", {
              product: intl.get("product"),
              unitOfMeasure
            })}
          </p>
        )}
      </>
    );
  }
}

export default AuthProductDetailsComponent;
