import React from "react";
import { BrProps } from "@bloomreach/react-sdk";
import { ImageSet, Document } from "@bloomreach/spa-sdk";

import "./release-note.less";
import { Link } from "react-router-dom";
import { isLinkExternal } from "@elasticpath/ref-store/src/utils/helpers";
import RichText from "../RichText/rich-text";

const ReleaseNote = ({ component, page }: BrProps) => {
  const { document: documentRef } = component.getModels();
  const document = documentRef && page.getContent<Document>(documentRef);

  if (!document) {
    return null;
  }

  const {
    date,
    releaseInfoHeading,
    header,
    releaseNoteButtons,
    richTextBody
  } = document.getData();

  const options: any = {
    year: "numeric",
    month: "long",
    day: "numeric"
  };

  let body;
  if (richTextBody && richTextBody.value) {
    body = page.rewriteLinks(richTextBody.value);
  }

  const formattedDate = new Date(date).toLocaleDateString("en-US", options);

  const renderReleaseNoteComponent = () => (
    <div className="release-note">
      <div className="release-note-date">{formattedDate}</div>
      <h2 className="release-info-heading">{releaseInfoHeading}</h2>
      <h2 className="release-note-header">{header}</h2>
      <p className="release-note-body">
        <RichText content={body} noClass />
      </p>
      <div className="release-note-buttons">
        {releaseNoteButtons &&
          releaseNoteButtons.map(button => {
            const { buttonStyle, buttonLabel, buttonUrl } = button;
            const [isExternal, url] = isLinkExternal(buttonUrl);

            return (
              <Link
                to={isExternal ? { pathname: url } : url}
                target={!isExternal ? "_self" : "_blank"}
                rel={isExternal ? "noopener noreferrer" : ""}
                key={button.buttonUrl}
                className={`dast-btn ${
                  buttonStyle === "primary"
                    ? "dast-btn-primary"
                    : "dast-btn-secondary"
                }`}
              >
                {buttonLabel}
              </Link>
            );
          })}
      </div>
    </div>
  );

  return document ? (
    renderReleaseNoteComponent()
  ) : (
    <>Please fill in component data.</>
  );
};

export default ReleaseNote;
