import config from "../ep.config.json";
import { httpPost } from "./HttpService";

const {
  // DaikinApi,
  cortexApi: { scope }
} = config;

const options: any = {
  headers: {
    Authorization: `Bearer ${config.connectServicesToken}`
  }
};

/* ## getMincronOrdersIds
 *
 * @param epOrderId string
 *
 * @description Function returns mincron orders ids
 */
// eslint-disable-next-line import/prefer-default-export
export const getMincronOrdersIds = (epOrderId: string): Promise<any> => {
  const { EPOrderEnvPrefix } = config;
  const orderId = `${EPOrderEnvPrefix}ecomm_${epOrderId}`;
  const url = "/graphql?serviceName=retrieveOrderMincronOrderNumbers";
  const data = {
    query: `
          query Erp($ecommOrderNumber: String!) {
            erp {
                orderSubmissionStatus(ecommOrderNumber: $ecommOrderNumber) {
                    backOrderStatus
                    batchNumber
                    branchNumber
                    creditHold
                    creditLimit
                    customerWillCall
                    openBalance
                    orderNumber
                    paymentType
                    reference
                    submitterEmail
                    successOrError
              }
            }
        }
          `,
    variables: {
      ecommOrderNumber: orderId
    }
  };

  return httpPost(url, data, options);
};

export const getAvailability = (
  skus: string[],
  customerNumber: string,
  branchNumber?: string,
  zipCode?: string,
  latitude?: number,
  longitude?: number
): Promise<any> => {
  const url = "/graphql?serviceName=availability";
  const data = {
    query: `
    query Branches($skus: [String!]!, $customerNumber: String!, $branchNumber: String, $latitude: Float, $longitude: Float, $zipCode: String) {
      inventory {
        entitledInventory(skus: $skus, customerNumber: $customerNumber, branchNumber: $branchNumber, latitude: $latitude, longitude: $longitude, zipCode: $zipCode) {
          branches {
            branchNumber
            branchName
            inventory {
              sku
              qtyAvailable
              unitOfMeasure
            } 
            distance
          }
          regionRollups {
            qtyAvailable
            sku
          }
          hubInventory {
            branchNumber
            branchName
            city
            state
            distance
            inventory {
              sku
              qtyAvailable
              unitOfMeasure
            }
          }
        }
      }
    }
          `,
    variables: {
      skus,
      customerNumber,
      branchNumber,
      zipCode,
      latitude,
      longitude
    }
  };
  return httpPost(url, data, options);
};

/* ## getBranchesGQL
 *
 * @param customerNumber string
 *
 * @description Function for getting entitled branches
 */
export const getBranchesGQL = (customerNumber: string): Promise<any> => {
  const url = "/graphql?serviceName=entitledBranches";
  const data = {
    query: `
    query Customer($customerNumber: String!) {
      customer(customerNumber: $customerNumber) {
        branchEntitlements {
          address
          address1
          addressee
          branchName
          branchNumber
          branchType
          city
          country
          distance
          divisionCode
          divisionName
          email
          fax
          formattedAddress
          geocoded
          latitude
          longitude
          otherContact
          phone
          regionCode
          regionName
          state
          timezone
          zip
          tags
        }
      }
    }
          `,
    variables: {
      customerNumber
    }
  };

  return httpPost(url, data, options);
};

export const getContractOrdersGQL = (customerNumber: string): Promise<any> => {
  const url = "/graphql?serviceName=contracts";
  const data = {
    query: `
        query Contracts($customerNumber: String!) {
  customer(customerNumber: $customerNumber) {
    contracts {
      branchNo
      branchShipAddress1
      branchShipAddress2
      branchShipAddress3
      branchShipCity
      branchShipState
      branchShipZip
      customerNumber
      description
      enteredBy
      expiration
      firstRelease
      firstShipment
      jobName
      jobNumber
      lastRelease
      lastShipment
      orderNumber
      orderPlaced
      otherChargesAmt
      purchaseOrderNumber
      pricing
      promisedShip
      shipToAddress1
      shipToAddress2
      shipToAddress3
      shipToCity
      shipToState
      shipToZip
      status
      subTotal
      tax
      total
      detailLines {
        discountPercent
        linePrice
        lineControlNumber
        netPrice
        orderNumber
        quantity
        unitOfMeasure
        sku
        releasedQuantity
        shipFromBranch
        unitPrice

      }
    }
  }
}
          `,
    variables: {
      customerNumber
    }
  };

  return httpPost(url, data, options);
};

export const priceCatalogGQL = (header: any): Promise<any> => {
  const url = "/graphql?serviceName=prices";
  const data = {
    query: `
       query Query($header: QuoteInput!) {
  quote(header: $header) {
    shipments {
      items {
        price
        quantity
        sku
        lineTotal
      }
    }
  }
}
          `,
    variables: {
      header
    }
  };

  return httpPost(url, data, options);
};

export const orderSummaryGQL = (header: any): Promise<any> => {
  const url = "/graphql?serviceName=prices";
  const data = {
    query: `
    query Quote($header: QuoteInput!) {
      quote(header: $header) {
        subTotal
        total
        tax
        shipments {
          items {
            sku
            quantity
            price
            lineTotal
          }
        }
      }
    }
          `,
    variables: {
      header
    }
  };

  return httpPost(url, data, options);
};

export const getOrderHistoryGQL = (
  sortOptions: any,
  customerNumber: string,
  filters: any
): Promise<any> => {
  const {
    skus,
    orderNumbers,
    orderStatus,
    dateFrom,
    dateTo,
    limit,
    skip,
    poNumbers,
    jobNumbers,
    branchNumber
  } = filters;
  const { sortKey, sortSeq } = sortOptions;
  const url = "/graphql?serviceName=orderHistory";
  const data = {
    query: `
    query getOrderHistory( $customerNumber: String!, $skus: [String!], $orderStatus: String, $orderNumbers: [String!], $dateFrom: String, $dateTo: String, $limit: Int, $skip: Int, $poNumbers: [String!], $jobNumbers: [String!], $branchNumber: String $sortKey: String $sortSeq: String) {
      customer(customerNumber: $customerNumber ) {
        orders(skus: $skus, orderStatus: $orderStatus, orderNumbers: $orderNumbers, dateFrom: $dateFrom, dateTo: $dateTo, limit: $limit, skip: $skip, poNumbers: $poNumbers, jobNumbers: $jobNumbers, branchNumber: $branchNumber sortKey: $sortKey sortSeq: $sortSeq) {
          branchNumber
          backOrderStatus
          orderNumber
          orderStatusCode
          orderPlacedDate
          purchaseOrderNumber
          jobNumber
          jobName
          orderByName
          invoiceDate
          ordersShippingAddress1
          ordersShippingAddress2
          shippingCity
          shippingState
          promisedShipDate
          orderMethodOfShipment
          totalInvoiceAmount
          orderStatusLookup {
            ecommDescription
          }
          shipmentStatusLookup {
            ecommDescription
          }
        }
      }
    }
          `,
    variables: {
      skus,
      orderStatus,
      orderNumbers,
      dateFrom,
      dateTo,
      customerNumber,
      limit,
      skip,
      poNumbers,
      jobNumbers,
      branchNumber,
      sortKey,
      sortSeq
    }
  };

  return httpPost(url, data, options);
};

export const getInvoiceReportGQL = (
  customerNumber,
  invoiceNumber
): Promise<any> => {
  const url = "/graphql?serviceName=invoiceReport";
  const data = {
    query: `
    query getInvoiceReport($invoiceNumber: String!, $customerNumber: String!) {
      customer(customerNumber: $customerNumber) {
        invoicePdfData(invoiceNumber: $invoiceNumber)
      }
    }
          `,
    variables: { customerNumber, invoiceNumber }
  };

  return httpPost(url, data, options);
};

export const getOrderHistoryDetailsGQL = (
  customerNumber: string,
  orderNumbers: any
): Promise<any> => {
  const url = "/graphql?serviceName=orderHistoryDetails";

  const data = {
    query: `
    query getOrderHistoryDetails( $customerNumber: String!, $orderNumbers: [String!]) {
      customer(customerNumber: $customerNumber ) {
        orders(orderNumbers: $orderNumbers) {
          branchNumber
          backOrderStatus
          shipfromBranchNumber
          orderPlacedDate
          promisedShipDate
          shipDate
          customerNumber
          orderNumber
          purchaseOrderNumber
          orderMethodOfShipment
          orderByName
          trackingNo
          ordersShippingAddress1
          ordersShippingAddress2
          shippingCity
          shippingState
          shipCode
          shipCodeDesc
          mainShippingZipCode
          subTotalAmount
          orderTaxAmount
          otherChargesTotalAmt
          orderByName
          gstTaxAmount
          invoiceDate
          jobNumber
          jobName 
          totalInvoiceAmount
          orderStatusLookup {
            ecommDescription
          }
          pickStatusLookup {
            ecommDescription
          }
          shipmentStatusLookup {
            ecommDescription
          }
          details {
            quantityOrdered
            quantityBackordered
            quantityShipped
            sku
            description
            netPrice
            linePrice
            serialNumbers
          }
          submittedOrder {
            paymentType
            payment {
              authNumber
              cardType
              referenceNumber
            }
          }
        }
      }
    }
          `,
    variables: {
      orderNumbers,
      customerNumber
    }
  };

  return httpPost(url, data, options);
};

export const getCreditInformationGQL = (customerNumber): Promise<any> => {
  const url = "/graphql?serviceName=accountCreditHistory";
  const data = {
    query: `
    query getCreditInformationGQL($customerNumber: String!) {
      customer(customerNumber: $customerNumber) {
        accountHistory {
          average6MonthSales
          creditLimit
          customerNumber
          due120Days
          due30Days
          due60Days
          last12MonthSales
          lastPayAmt
          lastPayDate
          due90Days
          currentBalanceDue
          totalBalanceDue
        }
      }
    }
          `,
    variables: { customerNumber }
  };

  return httpPost(url, data, options);
};

export const getStandardOrderListGQL = (
  customerNumber: string
): Promise<any> => {
  const url = "/graphql?serviceName=standardOrderList";
  const data = {
    query: `
    query StandardOrderList($customerNumber: String!) {
      customer(customerNumber: $customerNumber) {
        standardOrderList {
          orderNumber
          name
        }
      }
    }
    `,
    variables: {
      customerNumber
    }
  };
  return httpPost(url, data, options);
};

export const getStandardOrderDetailsGQL = (
  customerNumber: string,
  orderNumber: string
): Promise<any> => {
  const url = "/graphql?serviceName=standardOrderDetails";
  const data = {
    query: `
    query StandardOrder($customerNumber: String!, $orderNumber: String!) {
      customer(customerNumber: $customerNumber) {
        standardOrder(orderNumber: $orderNumber) {
          customerNumber
          detail {
            sequenceNumber
            sku
            description
            unitOfMeasure
          }
          name
          orderNumber
        }
      }
    }
    `,
    variables: {
      customerNumber,
      orderNumber
    }
  };
  return httpPost(url, data, options);
};

export const checkEntitlementGQL = async (
  customerNumber: string,
  skus: string[]
): Promise<any> => {
  const url = "/graphql?serviceName=ProductEntitlements";
  const data = {
    query: `
  query ProductEntitlements($skus: [String!]!, $customerNumber: String!) {
    customer(customerNumber: $customerNumber) {
      productEntitlements(skus: $skus) {
        entitled
        brand
        sku
        valid
      }
    }
  }
  `,
    variables: {
      customerNumber,
      skus
    }
  };
  return httpPost(url, data, options);
};
