import config from "../ep.config.json";
import { httpGet, httpPost, httpPut } from "./HttpService";

const {
  // DaikinApi,
  cortexApi: { scope }
} = config;

const options: any = {
  headers: {
    Authorization: `Bearer ${config.connectServicesToken}`
  }
};

/**
 * ## getPartsAndManifests
 *
 * @param sku: string;
 * @param serialNumber: string;
 *
 * @description Function that returns parts and manifests for specific sku and serial numbers
 */
export const getPartsAndManifests = (
  sku: string,
  serialNumber: string
): Promise<any> => {
  const url: string = `/connect/1.0/parts?sku=${sku}&serial=${serialNumber}`;

  return httpGet(url, options);
};

/**
 * ## listStatements
 *
 * @param customerNumber: string;
 *
 * @description Function that lists all avaliable statements for the user.
 */
// eslint-disable-next-line import/prefer-default-export
export const listStatements = (customerNumber: string): Promise<any> => {
  const url: string = `/connect/1.0/account/statement/list?customerNumber=${customerNumber}`;
  return httpGet(url, options);
};

/**
 * ## getAccountStatement
 * @param customerNumber: string;
 * @param statementDate: string;
 *
 * @description Function returns account statements for specified time period.
 */
// eslint-disable-next-line import/prefer-default-export
export const getAccountStatement = async (
  customerNumber: string,
  statementDate: string
): Promise<any> => {
  const url = `/connect/1.0/account/statement?customerNumber=${customerNumber}&statementDate=${statementDate}&format=PDF`;

  return httpGet(url, { ...options, responseType: "blob" });
};

/**
 * ## getInvoiceReport
 * @param orderNumber: string - this should be the same as invoice number;
 * @param customerNumber: string;
 *
 * @description Function returns generated invoice report (for the provided order number) in PDF-format.
 */
// eslint-disable-next-line import/prefer-default-export
export const getInvoiceReport = (
  orderNumber: string,
  customerNumber: string
): Promise<any> => {
  const url = `/connect/1.0/account/invoice?customerNumber=${customerNumber}&invoiceNumber=${orderNumber}&format=PDF`;

  return httpGet(url, { ...options, responseType: "blob" });
};

/**
 * ## sendContactUsForm
 *
 * @param body string: - stringified message;
 *
 * @description Function sends the specified message to the "Contact Us" recipient
 * appropriate to the brand. Message is specified in a JSON body.
 * Brand must be Enum: "DAIKIN" | "GOODMAN" | "AMANA". (Daikin API 1.5)
 */
export const sendContactUsForm = async (body: string): Promise<any> => {
  const brandName = scope.toUpperCase();

  const url = `/connect/1.0/contactus?brand=${brandName.toUpperCase()}&message=${encodeURIComponent(
    body
  )}`;
  try {
    const response = await httpPost(url, null, options);

    return response;
  } catch (err) {
    return err;
  }
};

/**
 * ## getMatchUps
 *
 * @param branchNumber: string;
 *
 * @description Function returns the information about the match up products for specified branch
 */

export const getMatchUps = (
  branch: string,
  brand: string = scope.toUpperCase()
): Promise<any> => {
  const url = `/connect/1.0/matchups/sheets?branch=${branch}&brand=${brand}`;
  return httpGet(url, options);
};

/**
 * @deprecated replaced to priceCatalogGQL
 * ## priceCatalog
 *
 * @param body: ProductPriceRequest;
 *
 * @description Function returns pricing for a list of items.
 */
export const priceCatalog = (body): Promise<any> => {
  const url = "/connect/1.0/prices/catalog";
  return httpPost(url, body, options);
};

/**
 * @deprecated replaced to getContractOrdersGQL
 * ## getContractOrders
 *
 * @param customerNumber : string;
 * @param contractNumber : string;
 * @param poNumber : string;
 *
 * @description Function that returns the contract orders for the customer, if any exist.
 * If only the customer number is specified, all contract orders for the customer will be returned.
 * If the contract number or PO number is specified, the results will be filtered by those values.
 */
export const getContractOrders = (
  customerNumber: string,
  contractNumber?: string,
  poNumber?: string
): Promise<any> => {
  const contractNumberParam = contractNumber
    ? `&contractNumber=${contractNumber}`
    : "";
  const poNumberParam = poNumber ? `&poNumber=${poNumber}` : "";
  const url = `/connect/2.0/account/contracts?customerNumber=${customerNumber}${contractNumberParam}${poNumberParam}`;
  return httpGet(url, options);
};

/**
 * ## checkEntitlementSku
 *
 * @param customerNumber string
 * @param skus string - Pass multiple parameters with | or , delimiter, like so "sku1|sku2" or "sku1,sku2".
 * @param distributor? string Default value is "GOODMAN"
 *
 * @description Function returns entitlement information for each sku based on the current user.
 */
export const checkEntitlementSku = async (
  customerNumber: string,
  skus: string,
  distributor?: string,
  email?: string
): Promise<any> => {
  const emailParameter = email ? `&email=${email}` : "";
  const url = `/connect/1.0/products/entitled?customerNumber=${customerNumber}&skus=${skus}&distributor=${distributor ||
    "GOODMAN"}${emailParameter}`;
  return httpGet(url, options);
};

/* ## getAvailability
 *
 * @param customerNumber string
 * @param branch string
 * @param skus string - Pass multiple parameters with | or , delimiter, like so "sku1|sku2" or "sku1,sku2".
 * @parem distributor string
 * @param lat number
 * @param lng number
 *
 * @description Function that returns availability inventory
 */

export const getAvailability = (
  customerNumber: string,
  branch: string,
  skus: string,
  distributors: string,
  lat: number,
  lng: number,
  showAll?: boolean,
  clientId?: string,
  fulfillmentTypeId?: string
): Promise<any> => {
  let url;

  /*
  Untill Motili availability service is updated to connect services we have to use the old service
  */
  if (scope === "motili") {
    const clientIdParam = clientId ? `&clientId=${clientId}` : "";
    const fulfillmentTypeIdParam = fulfillmentTypeId
      ? `&fulfillmentTypeId=${fulfillmentTypeId}`
      : "";
    const products = JSON.stringify({ productIds: skus.split("|") });
    url = `/1.5/motili/inventory?latitude=${lat}&longitude=${lng}${clientIdParam}${fulfillmentTypeIdParam}&inventory=${encodeURIComponent(
      products
    )}`;
    const motiliOptions = {
      timeout: 1000 * 5
    };
    return httpGet(url, motiliOptions);
  }

  url = `/connect/1.0/branches/availability?customerNumber=${customerNumber}&skus=${skus}&branch=${branch}&distributors=${distributors}&lat=${lat}&lng=${lng}&showAll=${showAll}`;
  return httpGet(url, options);
};

/* ## getAvailabilityDGA
 *
 * @description Function that returns availability inventory for DGA (not Motili), only with the needed information
 */

export const getAvailabilityDGA = async (
  customerNumber: string,
  skus: string[],
  branchNumber: string,
  details?: boolean,
  limit?: number,
  skip?: number,
  latitude?: number,
  longitude?: number,
  zipCode?: string
): Promise<any> => {
  let url;
  url = `/starlite/inventory/availability?customerNumber=${customerNumber}&branchNumber=${branchNumber}&skus=${skus}`;

  const detailsToUse = details || "true";
  url = url.concat(`&details=${detailsToUse}`);

  if (limit) {
    url = url.concat(`&limit=${limit}`);
  }
  if (skip) {
    url = url.concat(`&skip=${skip}`);
  }
  if (latitude && longitude) {
    url = url.concat(`&latitude=${latitude}&longitude=${longitude}`);
  }
  if (zipCode) {
    url = url.concat(`&zipCode=${zipCode}`);
  }
  return httpGet(url, options);
};

/* ## getRealTimeInventoryDGA
 *
 * @description Function that returns availability inventory for DGA (not Motili), only with the needed information
 */

export const getRealTimeInventoryDGA = async (
  customerNumber: string,
  skus: string[],
  branchNumbers: string[]
): Promise<any> => {
  const url = `/jsonrpc`;
  const body = {
    jsonrpc: "2.0",
    id: 2,
    method: "inventory.getRealTimeInventory",
    params: {
      customerNumber,
      skus,
      branchNumbers
    }
  };
  const response = await httpPost(url, body, options);
  return response;
};

/* ## getRegionalInventoryDGA
 *
 * @description Function that returns availability inventory for DGA (not Motili), only with the needed information
 */

export const getRegionalInventoryDGA = async (
  customerNumber: string,
  skus: string[],
  branchNumber: string
): Promise<any> => {
  const url = `/jsonrpc`;
  const body = {
    jsonrpc: "2.0",
    id: 2,
    method: "inventory.getRegionalAvailability",
    params: {
      customerNumber,
      skus,
      branchNumber
    }
  };
  const response = await httpPost(url, body, options);
  return response;
};

/* ## getAvailabilityMotili
 *
 * @param skus string - Pass multiple parameters with | or , delimiter, like so "sku1|sku2" or "sku1,sku2".
 * @param lat number
 * @param lng number
 *
 * @description Function that returns availability inventory only for Motili
 */

export const getAvailabilityMotili = (
  skus: string,
  lat: number,
  lng: number,
  clientId?: string,
  fulfillmentTypeId?: string
): Promise<any> => {
  const clientIdParam = clientId ? `&clientId=${clientId}` : "";
  const fulfillmentTypeIdParam = fulfillmentTypeId
    ? `&fulfillmentTypeId=${fulfillmentTypeId}`
    : "";
  const products = JSON.stringify({ productIds: skus.split("|") });
  const url = `/1.5/motili/inventory?latitude=${lat}&longitude=${lng}${clientIdParam}${fulfillmentTypeIdParam}&inventory=${encodeURIComponent(
    products
  )}`;
  const motiliOptions = {
    timeout: 1000 * 5
  };
  return httpGet(url, motiliOptions);
};

/**
 * ## getWarrantyModels
 *
 * @param serialNumber: string;
 *
 * @description Function returns models for the supplied serial number
 */
export const getWarrantyModels = (serialNumber: string): Promise<any> => {
  const url = `/connect/1.0/warranty/models?serialNumber=${serialNumber}`;
  return httpGet(url, options);
};

/**
 * ## getWarrantyEntitlements
 *
 * @param serialNumber: string;
 * @param model: string;
 * @param installType: string;
 * @param lastName: string;
 *
 * @description Function returns warranty entitlements
 */
export const getWarrantyEntitlements = (
  serialNumber: string,
  model: string,
  installType: string,
  lastName: string
): Promise<any> => {
  const queryParams: string = `?serialNumber=${serialNumber}&model=${model}&installType=${installType}&lastName=${lastName ||
    ""}`;
  const url = `/connect/1.0/warranty/entitlements${queryParams}`;
  return httpGet(url, options);
};

/**
 * ## getOrderHistory
 *
 * @param query string
 *
 * @description Function returns the information about the query specified
 * orders of the logged-in user or all of the customer's orders if no query is specified.
 */
export const getOrderHistory = async (
  customerNumber: string,
  query: string = "",
  cancelToken?
): Promise<any> => {
  let url;
  /*
  Untill Motili order history service is updated to connect services we have to use the old service
  */
  if (scope === "motili") {
    url = `/1.5/motili/orders${query ? `?${query}` : ""}`;
  } else {
    url = `/connect/1.0/orders?customerNumber=${customerNumber}${
      query ? `&${query}` : ""
    }`;
  }
  return httpGet(
    url,
    scope === "motili"
      ? {}
      : { ...options, cancelToken: cancelToken ? cancelToken.token : null }
  );
};

/**
 * ## getCreditInformation
 *
 * @param customerNumber: string;
 *
 * @description Function returns the current account history for a given account,
 * specified by email address.
 */
export const getCreditInformation = async (
  customerNumber: string
): Promise<any> => {
  const url = `connect/1.0/account/history?customerNumber=${customerNumber}`;
  try {
    const response = await httpGet(url);

    return response.data;
  } catch (error) {
    throw Error(error);
  }
};

/**
 * ## completeInventory
 *
 * @param body any - as
 * {
 * customerNumber: string;
 * branchNumber: string;
 * distributor: string;
 * items: Array<{sku: string; quantity: number}>
 * }
 *
 * @description Function that returns the list of branches that can completely satisfy the order without a back-order.
 */
export const completeInventory = (body: any) => {
  const url = `/connect/1.0/branches/inventory/complete`;
  return httpPost(url, body, options);
};

/* ## getMincronOrderId
 *
 * @param customerNumber string
 * @param epOrderId string
 *
 * @description Function returns mincron order id
 */
export const getMincronOrderId = (
  customerNumber: string,
  epOrderId: string
): Promise<any> => {
  const url = `/connect/1.0/ecomm/submission?ecommOrderNumbers=ecomm_${epOrderId}&customerNumber=${customerNumber}`;

  return httpGet(url, options);
};

/* ## getOrderHistoryDetails
 *
 * @param customerNumber: string
 *
 * @description Function that returns csv format of order history details
 */
export const getOrderHistoryDetails = (
  customerNumber: string,
  query: string
): Promise<any> => {
  const url = `/connect/1.0/orders?customerNumber=${customerNumber}&${query}`;

  return httpGet(url, options);
};

/* ## getAssets
 *
 * @param skus: string
 *
 * @description Function that returns product assets (documents)
 */
export const getAssets = async (skus: string): Promise<any> => {
  const url = `/jsonrpc`;
  const body = {
    jsonrpc: "2.0",
    id: 0,
    method: "documentLibrary.getAssets",
    params: {
      skus: [skus]
    }
  };
  const response = await httpPost(url, body, options);

  return response;
};

export const getTaxonomy = async (): Promise<any> => {
  const url = `/jsonrpc`;
  const body = {
    jsonrpc: "2.0",
    id: 0,
    method: "documentLibrary.getTaxonomy",
    params: {}
  };
  const response = await httpPost(url, body, options);

  return response;
};
