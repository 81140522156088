/**
 * Copyright © 2019 Elastic Path Software Inc. All rights reserved.
 *
 * This is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This software is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with this license. If not, see
 *
 *     https://www.gnu.org/licenses/
 *
 *
 */

import React from "react";
import { withRouter, RouteComponentProps, Redirect } from "react-router-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Modal from "react-responsive-modal";
import { BrComponent } from "@bloomreach/react-sdk";
import intl from "react-intl-universal";
import { MainContext } from "@elasticpath/ref-store/src/contexts/MainContext";
import { zoomNavigation } from "@zilker/store-components/src/static/zoom";
import {
  checkTokensExpired,
  checkResponse,
  pushToMaintenace,
  setLocalStorageFlag,
  getLocalStorageFlag,
  validateRegisterForm,
  authenticateUser,
  getSsoErrorInformation
} from "@elasticpath/ref-store/src/utils/helpers";
import { load } from "@zilker/store-components";
import AppModalRedirectToDownload from "../AppModalRedirectToDownload/AppModalRedirectToDownload";
import AppHeaderNavigationBarMain from "../AppHeaderNavigationBarMain/AppHeaderNavigationBarMain";
import AppHeaderTopNav from "../AppHeaderTopNav/appheadertopnav.main";
import AppHeaderBottomNav from "../AppHeaderBottomNav/appheaderbottomnav.main";
import BulkOrderMain from "../BulkOrder/bulkorder.main";

import { cortexFetch } from "../utils/Cortex";
import { getConfig, IEpConfig } from "../utils/ConfigProvider";
import { registerMincronAccount } from "../../../app/src/services/EpServices";
import detectIEBrowser from "../../../app/src/validation/detectIEBrowser";
import JobDropdownComponent from "../JobDropdownComponent/JobDropdownComponent";
import BranchDropdownComponent from "../BranchDropdownComponent/BranchDropdownComponent";
import ClientDropdownComponent from "../ClientDropdownComponent/ClientDropdownComponent";
import Popup from "../Popup/Popup";
import { ReactComponent as InfoIcon } from "../../../app/src/images/icons/info-icon.svg";

import "./appheader.main.less";

let Config: IEpConfig | any = {};

interface AppHeaderMainProps extends RouteComponentProps {
  onCurrencyChange: (...args: any[]) => any;
  onAuthRedirect: (...args: any[]) => any;
  brSuggest: (...args: any[]) => any;
  isInStandaloneMode: boolean;
  locationSearchData: string;
  appHeaderLinks: {
    [key: string]: any;
  };
  appHeaderLoginLinks: {
    [key: string]: any;
  };
  appHeaderNavigationLinks: {
    [key: string]: any;
  };
  appHeaderTopLinks: {
    [key: string]: any;
  };
  appModalLoginLinks: {
    [key: string]: any;
  };
  match: any;
  history: any;
}

interface InputRegisterForm {
  mincronCustomerNumber: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
}

interface InputRegisterFormErrors {
  mincronCustomerNumber: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
}

enum SsoDCSErrorId {
  ADMIN_USER_NOT_APPROVED = "ADMIN_USER_NOT_APPROVED",
  CUSTOMER_NOT_FOUND = "CUSTOMER_NOT_FOUND",
  SUBUSER_USER_NOT_APPROVED = "SUBUSER_USER_NOT_APPROVED",
  SUBUSER_NOT_APPROVED = "SUBUSER_NOT_APPROVED",
  EP_AUTH_TOKEN_MISSING_FIELDS = "EP_AUTH_TOKEN_MISSING_FIELDS"
}

enum SsoEPUserCreationError {
  EP_USER_NOT_UDPATED = "EP_USER_NOT_UDPATED",
  EP_USER_NOT_CREATED = "EP_USER_NOT_CREATED",
  EP_ACCOUNT_NOT_CREATED = "EP_ACCOUNT_NOT_CREATED",
  EP_USER_ACCOUNT_ASSOCIATION_NOT_CREATED = "EP_USER_ACCOUNT_ASSOCIATION_NOT_CREATED"
}

interface AppHeaderMainState {
  areBranchesFetched: boolean;
  cartData: any;
  isOffline: boolean;
  isBulkModalOpened: boolean;
  isDesktop: boolean;
  isTablet: boolean;
  isMobile: boolean;
  isMobileMin: boolean;
  totalQuantity: number;
  guestLogged: boolean;
  ssoLogged: boolean;
  cortexNavigations: any;
  scrollClass: boolean;
  areContractsFetched: boolean;
  isNavExpanded: boolean;
  openDownloadModal: boolean;
  openInvalidUserRightsModal: boolean;
  prevLocation: Location | null;
  openSsoErrorModal: boolean;
  showPopup: boolean;
  registerForm: InputRegisterForm;
  registerFormErrors: InputRegisterFormErrors;
  openRegistrationSuccessfulModal: boolean;
}

const inputRegisterForm: InputRegisterForm = {
  mincronCustomerNumber: "",
  firstName: "",
  lastName: "",
  email: "",
  phoneNumber: ""
};

const inputRegisterFormErrors: InputRegisterFormErrors = {
  mincronCustomerNumber: "",
  firstName: "",
  lastName: "",
  email: "",
  phoneNumber: ""
};

class AppHeaderMain extends React.Component<
  AppHeaderMainProps,
  AppHeaderMainState
> {
  static contextType = MainContext;

  requestedNavigationHttp: boolean;

  constructor(props) {
    super(props);
    const epConfig = getConfig();
    Config = epConfig.config;
    this.requestedNavigationHttp = false;
    this.state = {
      areBranchesFetched: false,
      totalQuantity: 0,
      cartData: undefined,
      isOffline: false,
      isBulkModalOpened: false,
      isDesktop: window.innerWidth >= 1024,
      isTablet: window.innerWidth < 1024 && window.innerWidth >= 768,
      isMobile: window.innerWidth < 768,
      isMobileMin: window.innerWidth < 425,
      guestLogged: false,
      ssoLogged: false,
      scrollClass: false,
      cortexNavigations: undefined,
      areContractsFetched: false,
      isNavExpanded: false,
      openDownloadModal: false,
      openInvalidUserRightsModal: false,
      prevLocation: null,
      openSsoErrorModal: false,
      showPopup: false,
      registerForm: inputRegisterForm,
      registerFormErrors: inputRegisterFormErrors,
      openRegistrationSuccessfulModal: false
    };

    this.handleBulkModalClose = this.handleBulkModalClose.bind(this);
    this.updatePredicate = this.updatePredicate.bind(this);
    this.openModal = this.openModal.bind(this);
    this.redirectToMainPage = this.redirectToMainPage.bind(this);
    this.goBack = this.goBack.bind(this);
    this.fetchCartData = this.fetchCartData.bind(this);
    this.onFocus = this.onFocus.bind(this);
    this.generatePageTitle = this.generatePageTitle.bind(this);
    this.toggleMobile = this.toggleMobile.bind(this);
    this.toggleNavExpanded = this.toggleNavExpanded.bind(this);
    this.closeDownloadModal = this.closeDownloadModal.bind(this);
    this.handleCloseInvalidUserRightsModal = this.handleCloseInvalidUserRightsModal.bind(
      this
    );
    this.handleCloseSsoErrorModal = this.handleCloseSsoErrorModal.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.registerAccount = this.registerAccount.bind(this);
    this.handleCloseRegistrationSuccessfulModal = this.handleCloseRegistrationSuccessfulModal.bind(
      this
    );
  }

  componentDidMount() {
    load();
    const {
      context: {
        cart: { isCartFetchReady }
      }
    } = this;
    let ssoError;
    const ssoErrorString = localStorage.getItem("sso_error");
    try {
      ssoError = JSON.parse(ssoErrorString);
    } catch (err) {
      ssoError = {
        errorMsgId: SsoDCSErrorId.EP_AUTH_TOKEN_MISSING_FIELDS,
        admins: ""
      };
      console.error(err);
    }
    if (ssoError) {
      const { firstName, lastName, phoneNumber, email } = ssoError;
      this.setState({
        registerForm: {
          mincronCustomerNumber: "",
          firstName,
          lastName,
          email,
          phoneNumber
        }
      });
    }
    if (isCartFetchReady) {
      this.fetchCartData();
    }
    window.addEventListener("resize", this.updatePredicate);
    this.fetchCartData();
    this.fetchBranches();
    this.fetchContracts();
    this.shouldOpenDownloadModal();
    window.onscroll = () => this.updateScroll();
    window.addEventListener("focus", this.onFocus);

    if (localStorage.getItem("user_rights") === "false") {
      this.setState({
        openInvalidUserRightsModal: true
      });
    } else if (localStorage.getItem("sso_error")) {
      this.setState({
        openSsoErrorModal: true
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    this.fetchCartData();
    this.fetchBranches();
    this.fetchContracts();

    const {
      location: { state },
      history
    } = this.props;
    const { isBulkModalOpened } = this.state;

    if (state && state.modal && !isBulkModalOpened) {
      this.openModal();
      history.replace();
    }

    const { guestLogged, ssoLogged, cortexNavigations } = prevState;
    const {
      context: {
        auth: { guestLoggedIn, isLoggedIn }
      }
    } = this;

    if (guestLoggedIn && !guestLogged && !cortexNavigations) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ guestLogged: true }, () => {
        if (!this.requestedNavigationHttp) {
          this.requestedNavigationHttp = true;
          this.fetchNavigationData();
        }
      });
    }
    if (isLoggedIn && !ssoLogged && !cortexNavigations) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ ssoLogged: true }, () => {
        if (!this.requestedNavigationHttp) {
          this.requestedNavigationHttp = true;
          this.fetchNavigationData();
        }
      });
    }
    if (!guestLoggedIn && guestLogged) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ guestLogged: false });
    }
    if (!isLoggedIn && ssoLogged) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ ssoLogged: false });
    }
  }

  onFocus() {
    const {
      context: {
        cart: { getCartDetails },
        auth: { isLoggedIn }
      }
    } = this;

    if (isLoggedIn) {
      getCartDetails();
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { location: newLocation } = nextProps;
    const { prevLocation: previousLocation } = prevState;
    if (newLocation !== previousLocation) {
      return {
        isNavExpanded: false,
        prevLocation: newLocation
      };
    }
    return null;
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updatePredicate);
    window.removeEventListener("focus", this.onFocus);
  }

  fetchNavigationData() {
    const {
      context: {
        auth: { logout }
      },
      props: { history }
    } = this;

    cortexFetch(`/?zoom=${zoomNavigation.join()}`)
      .then(res => checkResponse(res))
      .then(res => {
        if (res && res._navigations) {
          const cortexNavigations = res._navigations[0]._element;
          this.setState(
            {
              cortexNavigations
            },
            () => {
              this.requestedNavigationHttp = true;
            }
          );
        }
      })
      .catch(e => {
        if (checkTokensExpired(e)) {
          logout().catch(err =>
            pushToMaintenace(history, {
              e: err,
              errIn: "Logout => fetchNavigationData => AppHeaderMain.tsx"
            })
          );
        } else {
          pushToMaintenace(history, {
            e,
            errIn: "fetchNavigationData => AppHeaderMain.tsx"
          });
        }
      });
  }

  handleIsOffline = isOfflineValue => {
    this.setState({
      isOffline: isOfflineValue
    });
  };

  updatePredicate() {
    this.setState({
      isDesktop: window.innerWidth >= 1024,
      isTablet: window.innerWidth < 1024 && window.innerWidth >= 768,
      isMobile: window.innerWidth < 768,
      isMobileMin: window.innerWidth < 425
    });
  }

  updateScroll() {
    if (window.innerWidth > 768 && window.scrollY > 75)
      this.setState({ scrollClass: true });
    else this.setState({ scrollClass: false });
  }

  fetchContracts() {
    const {
      account: {
        accountDetails: { customerNumber }
      },
      contract: { fetchContracts, contractsList }
    } = this.context;
    const { areContractsFetched } = this.state;
    if (customerNumber && !contractsList && !areContractsFetched) {
      fetchContracts(customerNumber);
      this.setState({ areContractsFetched: true });
    }
  }

  fetchBranches() {
    const {
      branches,
      auth: { isTokenSet },
      cart: {
        cartDetails: { defaultCart }
      },
      account: { accountDetails }
    } = this.context;
    const { branchesList, fetchBranches } = branches;
    const { areBranchesFetched } = this.state;

    const { useMotiliEntitledBranchesService } = Config;
    const { homeBranch, customerNumber } = accountDetails;

    if (branches && !branchesList && isTokenSet && !areBranchesFetched) {
      // Condition for calling entitled branches service for Goodman/Daikin/Amana
      if (!useMotiliEntitledBranchesService && defaultCart && customerNumber) {
        fetchBranches(homeBranch, customerNumber);
        this.setState({
          areBranchesFetched: true
        });
      }
    }
  }

  fetchCartData() {
    const { cartData, totalQuantity } = this.state;
    const { cart } = this.context;
    const { cartDetails } = cart;

    if (cartDetails) {
      const { defaultCart, totalCount } = cartDetails;
      if (!cartData && defaultCart) {
        this.setState({
          cartData: defaultCart
        });
      }

      if (cartData && defaultCart && cartData.cartId !== defaultCart.cartId) {
        this.setState({
          cartData: defaultCart
        });
      }

      // Uncomment if multicart logic is needed here.
      // As per DGE-3116, the cart list is not fetched initially anymore.
      // The number of available carts is not visible here initially.
      // if (!multiCartData && cartList) {
      //   this.setState({
      //     multiCartData: cartList
      //   });
      // }

      if (!totalQuantity && totalCount) {
        this.setState({
          totalQuantity: totalCount
        });
      }
    }
  }

  goBack() {
    const { history } = this.props;
    history.goBack();
  }

  openModal() {
    const { isBulkModalOpened, isTablet } = this.state;
    if (isTablet) {
      this.setState(
        {
          isBulkModalOpened: !isBulkModalOpened
        },
        () => {
          document.body.style.position = "fixed";
          document.body.style.top = `-${window.scrollY}px`;
        }
      );
    } else {
      this.setState({
        isBulkModalOpened: !isBulkModalOpened
      });
    }
  }

  handleBulkModalClose() {
    this.setState(
      {
        isBulkModalOpened: false
      },
      () => {
        const scrollY = document.body.style.top;
        document.body.style.position = "";
        document.body.style.top = "";
        window.scrollTo(0, parseInt(scrollY || "0", 10) * -1);
      }
    );
  }

  redirectToMainPage() {
    const { history } = this.props;
    history.push("/");
  }

  /* eslint-disable class-methods-use-this */
  generatePageTitle(pathname: string): string {
    const pages = {
      "/myAccount/profile": `${intl.get("my-account")} ${intl.get("profile")}`,
      "/myAccount/creditInformation": `${intl.get("credit-information")}`,
      "/myAccount/statement": `${intl.get("statement")}`,
      "/myAccount/manageUsers": `${intl.get("manage-users")}`,
      "/myAccount/branchSettings": `${intl.get("branch-settings")}`,
      "/myAccount/orderHistory": `${intl.get("order-history")}`,
      "/myAccount/orderDetails": `${intl.get("order")} ${intl.get("details")}`,
      "/myAccount/invoiceQuery": `${intl.get("invoice-query")}`,
      "/myAccount/invoiceDetails": `${intl.get("invoice-information")}`,
      "/mycart": `${intl.get("my-cart")}`,
      "/checkout": `${intl.get("checkout")}`,
      "/order": `${intl.get("review-order")}`,
      "/purchaseReceipt": `${intl.get("order-confirmation")}`,
      "/warrantyLookup": `${intl.get("warranty-look-up")}`,
      "/myAccount/savedOrders": `${intl.get("my-saved-orders")}`,
      "/myAccount/standardOrders": `${intl.get("branch-standard-orders")}`,
      "/contactus": `${intl.get("contact-us")}`,
      "/help": `${intl.get("help")}`,
      "/aboutus": `${intl.get("about-us")}`,
      "/privacypolicy": `${intl.get("privacy-policy")}`,
      "/termsandconditions": `${intl.get("terms-and-conditions-title")}`,
      "/maintenance": `${intl.get("maintenance")}`,
      "/matchup": `${intl.get("match-up-title")}`,
      "/documentsLibrary": `${intl.get("documents-library")}`,
      "/registrationSuccessful": `${intl.get("registration-successful")}`,
      "/leadInvitationAccept": `${intl.get("lead-dashboard-invitation-page")}`,
      "/ahriSystemConfigurator": `${intl.get("ahri-redirect-page")}`
    };

    if (pathname && pathname.includes("/myAccount/orderDetails")) {
      return pages["/myAccount/orderDetails"];
    }

    if (pathname && pathname.includes("/myAccount/invoiceDetails")) {
      return pages["/myAccount/invoiceDetails"];
    }

    if (
      pathname &&
      pathname.includes("/myAccount/savedOrders") &&
      pathname.length > 22
    ) {
      // If the pathname is longer than 22 (/myAccount/savedOrders.length), it means that includes order (cart) name and id.
      return intl.get("saved-order-details");
    }

    return pages[pathname] || "";
  }

  toggleMobile() {
    this.setState({ isNavExpanded: false });
  }

  toggleNavExpanded() {
    const { isNavExpanded } = this.state;
    this.setState({ isNavExpanded: !isNavExpanded });
  }

  getMobileOS() {
    const ua = navigator.userAgent;
    if (/android/i.test(ua)) {
      return "Android";
    }
    if (/iPad|iPhone|iPod/.test(ua)) {
      return "iOS";
    }
    return "Other";
  }

  shouldOpenDownloadModal() {
    const {
      daikinConnectDealerAppLinks: { enabled }
    } = Config;
    const { openDownloadModal } = this.state;
    const isAndroid = this.getMobileOS() === "Android";
    const isIOS = this.getMobileOS() === "iOS";
    const downloadModalRejected = getLocalStorageFlag(
      "download_modal_rejected"
    );

    if (
      enabled &&
      !downloadModalRejected &&
      !openDownloadModal &&
      (isAndroid || isIOS)
    ) {
      this.setState({ openDownloadModal: true });
    }
  }

  closeDownloadModal() {
    const ttl = 30 * 24 * 60 * 60 * 1000;

    setLocalStorageFlag("download_modal_rejected", "true", ttl);
    this.setState({ openDownloadModal: false });
  }

  handleCloseInvalidUserRightsModal() {
    this.setState({
      openInvalidUserRightsModal: false
    });
    localStorage.removeItem("user_rights");
  }

  handleCloseRegistrationSuccessfulModal() {
    const { history } = this.props;
    this.setState({
      openRegistrationSuccessfulModal: false
    });
    authenticateUser(null, null, history);
  }

  handleCloseSsoErrorModal() {
    this.setState({
      openSsoErrorModal: false,
      registerFormErrors: inputRegisterFormErrors
    });
    localStorage.removeItem("sso_error");
  }

  async registerAccount() {
    const { registerForm } = this.state;
    const {
      context: {
        auth: { logout }
      },
      props: { history }
    } = this;

    const { errors, isValid } = validateRegisterForm(registerForm);

    if (isValid) {
      this.handleCloseSsoErrorModal();
    } else if (!isValid) {
      this.setState({
        registerFormErrors: errors
      });
      return;
    }

    const body = {
      "customer-number": registerForm.mincronCustomerNumber,
      email: registerForm.email,
      "first-name": registerForm.firstName,
      "last-name": registerForm.lastName,
      "phone-number": registerForm.phoneNumber.replace(/\D/g, "")
    };

    try {
      await registerMincronAccount(JSON.stringify(body));

      this.setState({
        openRegistrationSuccessfulModal: true
      });
    } catch (error) {
      const [errorId, adminEmails] = getSsoErrorInformation(error);

      if (checkTokensExpired(error)) {
        logout().catch(err =>
          pushToMaintenace(history, {
            e: err,
            errIn: "Logout => registerAccount => appheader.main.tsx"
          })
        );
      } else if (errorId) {
        const ssoError = {
          errorMsgId: errorId,
          admins: adminEmails,
          invalidCustomerNumberError: true
        };

        localStorage.setItem("sso_error", JSON.stringify(ssoError));
        this.setState({
          openSsoErrorModal: true
        });
      }
    }
  }

  handleInputChange(e) {
    e.persist();
    const { name, value } = e.target;
    const { registerForm } = this.state;
    const updatedState = { ...registerForm };
    updatedState[name] = value;

    this.setState(prevState => ({
      ...prevState,
      registerForm: { ...updatedState }
    }));
  }

  renderSsoError() {
    const ssoErrorString = localStorage.getItem("sso_error");
    let modalHeader;
    let modalBody;
    let ssoError;
    const { showPopup, registerForm, registerFormErrors } = this.state;

    try {
      ssoError = JSON.parse(ssoErrorString);
    } catch (err) {
      ssoError = {
        errorMsgId: SsoDCSErrorId.EP_AUTH_TOKEN_MISSING_FIELDS,
        admins: ""
      };
      console.error(err);
    }
    const { errorMsgId, admins, invalidCustomerNumberError } = ssoError;
    const adminEmails = admins && admins.replace(/,/g, ", ");
    const isCustomerNotFoundError =
      errorMsgId === SsoDCSErrorId.CUSTOMER_NOT_FOUND;

    const subuserNotApprovedModalBody = adminEmails ? (
      <>
        <p>
          {intl.get("subuser-approval-needed-body-pt1")}
          {intl.get("subuser-approval-needed-body-pt2")}
        </p>
        <p className="subuser-pending-approval">
          {intl.get("subuser-approval-needed-body-pt3", {
            adminEmails
          })}
        </p>
      </>
    ) : (
      <>
        <p>
          {intl.get("subuser-approval-needed-body-pt1")}
          {intl.get("subuser-approval-needed-body-pt4")}
          <a href={`mailto:${Config.supportEmail}`}>{Config.supportEmail}</a>
          {intl.get("subuser-approval-needed-body-pt5")}
        </p>
        <ul className="login-unsuccessful-modal-ul">
          <li>{intl.get("login-unsuccessful-message-field1")}</li>
          <li>{intl.get("login-unsuccessful-message-field2")}</li>
          <li>{intl.get("login-unsuccessful-message-field3")}</li>
          <li>{intl.get("login-unsuccessful-message-field4")}</li>
          <li>{intl.get("login-unsuccessful-message-field5")}</li>
        </ul>
      </>
    );

    switch (errorMsgId) {
      case SsoDCSErrorId.ADMIN_USER_NOT_APPROVED:
        modalHeader = intl.get("approval-needed-header");
        modalBody = <p>{intl.get("approval-needed-body")}</p>;
        break;
      case SsoDCSErrorId.SUBUSER_USER_NOT_APPROVED:
      case SsoDCSErrorId.SUBUSER_NOT_APPROVED:
        modalHeader = intl.get("approval-needed-header");
        modalBody = subuserNotApprovedModalBody;
        break;
      case SsoDCSErrorId.CUSTOMER_NOT_FOUND:
        modalHeader = intl.get("additional-information-required");
        modalBody = (
          <>
            <div>
              <span>
                {intl.get("login-unsuccessful-body-pt1")}
                {intl.get("additional-information-required-body")}
              </span>
              <div className="modal-wrapper">
                {showPopup && <Popup />}
                <div className="modal-item">
                  <label htmlFor="customer-account-number">
                    <span className="star">*</span>
                    {intl.get("customer-account-number")}
                    <InfoIcon
                      className="info-icon"
                      onMouseEnter={() => this.setPopup()}
                      onMouseLeave={() => this.hidePopup()}
                    />
                    :
                  </label>
                  <input
                    name="mincronCustomerNumber"
                    className={`${
                      registerFormErrors &&
                      registerFormErrors.mincronCustomerNumber
                        ? "validation-error-border"
                        : null
                    }`}
                    value={registerForm.mincronCustomerNumber}
                    onChange={this.handleInputChange}
                    aria-label={intl.get("customer-account-number")}
                    type="text"
                    required
                  />
                  {invalidCustomerNumberError ||
                  (registerFormErrors &&
                    registerFormErrors.mincronCustomerNumber) ? (
                    <p className="validation-error">
                      {" "}
                      {registerFormErrors.mincronCustomerNumber ||
                        intl.get("mincron-customer-number-error")}
                    </p>
                  ) : null}
                </div>
                <div className="modal-item">
                  <label htmlFor="first-name">
                    <span className="star">*</span>
                    {intl.get("first-name")}:
                  </label>
                  <input
                    name="firstName"
                    className={`${
                      registerFormErrors && registerFormErrors.firstName
                        ? "validation-error-border"
                        : null
                    }`}
                    value={registerForm.firstName}
                    aria-label={intl.get("first-name")}
                    onChange={this.handleInputChange}
                    type="text"
                    required
                  />
                  {registerFormErrors && registerFormErrors.firstName ? (
                    <p className="validation-error">
                      {" "}
                      {registerFormErrors.firstName}
                    </p>
                  ) : null}
                </div>
                <div className="modal-item">
                  <label htmlFor="last-name">
                    <span className="star">*</span>
                    {intl.get("last-name")}:
                  </label>
                  <input
                    name="lastName"
                    className={`${
                      registerFormErrors && registerFormErrors.lastName
                        ? "validation-error-border"
                        : null
                    }`}
                    value={registerForm.lastName}
                    onChange={this.handleInputChange}
                    aria-label={intl.get("last-name")}
                    type="text"
                    required
                  />
                  {registerFormErrors && registerFormErrors.lastName ? (
                    <p className="validation-error">
                      {" "}
                      {registerFormErrors.lastName}
                    </p>
                  ) : null}
                </div>
                <div className="modal-item">
                  <label htmlFor="email">
                    <span className="star">*</span>
                    {intl.get("email")}:
                  </label>
                  <input
                    name="email"
                    className={`${
                      registerFormErrors && registerFormErrors.email
                        ? "validation-error-border"
                        : null
                    }`}
                    value={registerForm.email}
                    onChange={this.handleInputChange}
                    aria-label={intl.get("email")}
                    type="email"
                    required
                  />
                  {registerFormErrors && registerFormErrors.email ? (
                    <p className="validation-error">
                      {" "}
                      {registerFormErrors.email}
                    </p>
                  ) : null}
                </div>
                <div className="modal-item">
                  <label htmlFor="phone-number">
                    <span className="star">*</span>
                    {intl.get("phone-number")}:
                  </label>
                  <input
                    name="phoneNumber"
                    className={`${
                      registerFormErrors && registerFormErrors.phoneNumber
                        ? "validation-error-border"
                        : null
                    }`}
                    value={registerForm.phoneNumber}
                    onChange={this.handleInputChange}
                    aria-label={intl.get("phone-number")}
                    type="text"
                    required
                  />
                  {registerFormErrors && registerFormErrors.phoneNumber ? (
                    <p className="validation-error">
                      {" "}
                      {registerFormErrors.phoneNumber}
                    </p>
                  ) : null}
                </div>
              </div>
            </div>
          </>
        );
        break;
      case SsoEPUserCreationError.EP_ACCOUNT_NOT_CREATED:
      case SsoEPUserCreationError.EP_USER_ACCOUNT_ASSOCIATION_NOT_CREATED:
      case SsoEPUserCreationError.EP_USER_NOT_CREATED:
      case SsoEPUserCreationError.EP_USER_NOT_UDPATED:
        modalHeader = intl.get("login-unsuccessful");
        modalBody = (
          <p>
            <span>{intl.get("internal-server-error-msg-pt1")}</span>
            <span>{intl.get("internal-server-error-msg-pt1-1")}</span>
            <span>{intl.get("internal-server-error-msg-pt2")}</span>
            <a href={`mailto:${Config.supportEmail}`}>{Config.supportEmail}</a>
            <span>{intl.get("internal-server-error-msg-pt3")}</span>
          </p>
        );
        break;
      case SsoDCSErrorId.EP_AUTH_TOKEN_MISSING_FIELDS:
        modalHeader = intl.get("login-unsuccessful");
        modalBody = (
          <p>
            <span>{intl.get("missing-fields-error-msg-pt1")}</span>
            <a href={`mailto:${Config.supportEmail}`}>{Config.supportEmail}</a>
            <span>{intl.get("missing-fields-error-msg-pt2")}</span>
          </p>
        );
        break;
      default:
        modalHeader = intl.get("login-unsuccessful");
        modalBody = (
          <p>
            <span>{intl.get("internal-server-error-msg-pt1")}</span>
            <span>{intl.get("internal-server-error-msg-pt1-2")}</span>
            <span>{intl.get("internal-server-error-msg-pt2")}</span>
            <a href={`mailto:${Config.supportEmail}`}>{Config.supportEmail}</a>
            <span>{intl.get("internal-server-error-msg-pt3")}</span>
          </p>
        );
    }

    return (
      <div className="modal-content ssoError" data-error={ssoError}>
        <div className="modal-header">
          <h2 className="modal-title">{modalHeader}</h2>
        </div>
        <div className="modal-body">
          {modalBody}
          <div className="permission-denied-modal__button-container">
            {isCustomerNotFoundError && (
              <button
                onClick={this.registerAccount}
                className="dast-btn dast-btn-primary wide"
                id="continue_with_cart_button"
                aria-label="close-permission-denied-modal"
                type="button"
              >
                {intl.get("register")}
              </button>
            )}
            <button
              onClick={this.handleCloseSsoErrorModal}
              className={`dast-btn ${
                isCustomerNotFoundError
                  ? "dast-btn-secondary"
                  : "dast-btn-primary"
              } wide`}
              id="continue_with_cart_button"
              aria-label="close-permission-denied-modal"
              type="button"
            >
              {intl.get("close")}
            </button>
          </div>
        </div>
      </div>
    );
  }

  renderRegistrtionSuccessfulModal() {
    const { openRegistrationSuccessfulModal, registerForm } = this.state;
    return (
      <Modal
        open={openRegistrationSuccessfulModal}
        onClose={this.handleCloseRegistrationSuccessfulModal}
      >
        <div className="modal-lg permission-denied-modal">
          <div className="modal-content">
            <div className="modal-header">
              <h2 className="modal-title">
                {intl.get("registration-successful")}
              </h2>
            </div>
            <div className="modal-body">
              <p>
                {intl.get("registration-successful-message", {
                  mincronNumber: registerForm.mincronCustomerNumber
                })}
              </p>
              <div className="permission-denied-modal__button-container">
                <button
                  onClick={this.handleCloseRegistrationSuccessfulModal}
                  className="dast-btn dast-btn-primary wide"
                  id="continue_with_cart_button"
                  aria-label="close-registration-successful-modal"
                  type="button"
                >
                  {intl.get("close")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }

  renderSsoErrorModal() {
    const { openSsoErrorModal } = this.state;
    return (
      <Modal open={openSsoErrorModal} onClose={this.handleCloseSsoErrorModal}>
        <div className="modal-lg permission-denied-modal">
          {this.renderSsoError()}
        </div>
      </Modal>
    );
  }

  setPopup() {
    this.setState({ showPopup: true });
  }

  hidePopup() {
    this.setState({ showPopup: false });
  }

  render() {
    const {
      isOffline,
      cartData,
      isBulkModalOpened,
      isDesktop,
      cortexNavigations,
      scrollClass,
      isNavExpanded,
      isMobile,
      openDownloadModal,
      openInvalidUserRightsModal,
      isMobileMin,
      openSsoErrorModal,
      openRegistrationSuccessfulModal
    } = this.state;
    const {
      onCurrencyChange,
      onAuthRedirect,
      locationSearchData,
      isInStandaloneMode,
      appHeaderLinks,
      appHeaderLoginLinks,
      appHeaderNavigationLinks,
      appHeaderTopLinks,
      appModalLoginLinks,
      brSuggest,
      history,
      location: { state, pathname }
    } = this.props;

    const {
      pageMetadata: { title: pageTitle, description, canonicalURL },
      oneTrustDataDomainScript
    } = Config;

    const {
      context: { auth, user, branches, account }
    } = this;
    const { isLoggedIn } = auth;
    const { userProfile } = user;
    const { accountDetails } = account;
    const {
      customerNumber,
      company,
      jobNumberRequired,
      homeBranch
    } = accountDetails;
    const { clientList, ecommerceUser } = userProfile;
    const { cart } = this.context;
    const { cartError } = cart;
    const {
      branchError,
      branchesErrorCode,
      branchesErrorMessage,
      findBranch
    } = branches;
    const availability = Boolean(cartData);
    const {
      cartDetails: { defaultCart }
    } = cart;
    const selectedBranch = defaultCart ? defaultCart.selectedBranch : null;

    const jobNumberExists = defaultCart && defaultCart.jobNumber;

    const shouldDisplayJobDropdown = jobNumberRequired || jobNumberExists;
    const homeBranchDetails = findBranch(homeBranch);

    const renderDownloadModal = () => {
      return (
        <AppModalRedirectToDownload
          closeDownloadModal={this.closeDownloadModal}
        />
      );
    };

    const renderInvalidUserRightsModal = () => {
      const { siteUrl, supportEmail } = Config;

      return (
        <Modal
          open={openInvalidUserRightsModal}
          onClose={this.handleCloseInvalidUserRightsModal}
        >
          <div className="modal-lg permission-denied-modal">
            <div className="modal-content">
              <div className="modal-header">
                <h2 className="modal-title">
                  {intl.get("login-unsuccessful")}
                </h2>
              </div>
              <div className="modal-body">
                <p>
                  <span>{intl.get("login-unsuccessful-message-pt1")}</span>
                  <span>{siteUrl}.</span>
                  <span>{intl.get("login-unsuccessful-message-pt2")}</span>
                  <a href={`mailto:${supportEmail}`}>{supportEmail}</a>
                  <span>{intl.get("login-unsuccessful-message-pt3")}</span>
                </p>
                <ul className="login-unsuccessful-modal-ul">
                  <li>{intl.get("login-unsuccessful-message-field1")}</li>
                  <li>{intl.get("login-unsuccessful-message-field2")}</li>
                  <li>{intl.get("login-unsuccessful-message-field3")}</li>
                  <li>{intl.get("login-unsuccessful-message-field4")}</li>
                </ul>
                <div className="permission-denied-modal__button-container">
                  <button
                    onClick={this.handleCloseInvalidUserRightsModal}
                    className="dast-btn dast-btn-primary wide"
                    id="continue_with_cart_button"
                    aria-label="close-permission-denied-modal"
                    type="button"
                  >
                    {intl.get("change-carts-ok")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      );
    };

    const shouldRenderBottomNav = () => {
      return (
        (isLoggedIn &&
          !userProfile.isInitialProfile &&
          userProfile.ecommerceUser) ||
        !isLoggedIn
      );
    };

    const handleLocaleChange = () => {
      window.location.reload();
    };

    return (
      <HelmetProvider>
        <header
          key="app-header"
          className={`app-header ${isNavExpanded ? "mobile-nav-expanded" : ""}`}
        >
          {oneTrustDataDomainScript && (
            <Helmet>
              {/* OptanonConsentNoticeStart */}
              <script
                src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
                type="text/javascript"
                data-domain-script={oneTrustDataDomainScript}
              />
              <script type="text/javascript">{`function OptanonWrapper() {}`}</script>
              {/* OptanonConsentNoticeEnd */}
            </Helmet>
          )}
          <Helmet>
            <title>
              {(state && state.name) ||
                this.generatePageTitle(pathname) ||
                pageTitle}
            </title>
            <meta
              name="description"
              content={
                (state && state.name) ||
                this.generatePageTitle(pathname) ||
                description
              }
            />
            <link rel="canonical" href={`${canonicalURL}${pathname}`} />
          </Helmet>
          <BrComponent path="header-banner-ticker" />
          <div className="main-header">
            <div className="container wrapper primary-nav-wrapper">
              <BrComponent path="header-logo" />
              <div
                className={`main-container ${
                  isInStandaloneMode ? "in-standalone" : ""
                }`}
              >
                {!detectIEBrowser() ? (
                  <div className="icons-header-container">
                    <AppHeaderTopNav
                      onCurrencyChange={onCurrencyChange}
                      onLocaleChange={handleLocaleChange}
                      appHeaderTopLinks={appHeaderTopLinks}
                      isLoggedIn={isLoggedIn}
                      userProfile={userProfile}
                      cart={cart}
                      homeBranchDetails={homeBranchDetails}
                      permission={availability}
                      onAuthRedirect={onAuthRedirect}
                      locationSearchData={locationSearchData}
                      appHeaderLoginLinks={appHeaderLoginLinks}
                      appModalLoginLinks={appModalLoginLinks}
                      history={history}
                      shouldDisplayJobDropdown={shouldDisplayJobDropdown}
                      cartData={cartData}
                      openModal={this.openModal}
                      appHeaderLinks={appHeaderLinks}
                      scrollClass={scrollClass}
                      isMobile={isMobile}
                      isMobileMin={isMobileMin}
                      accountDetails={accountDetails}
                    />
                  </div>
                ) : null}
                {!detectIEBrowser() ? (
                  <BulkOrderMain
                    isBulkModalOpened={isBulkModalOpened}
                    handleClose={this.handleBulkModalClose}
                    history={history}
                    auth={auth}
                  />
                ) : null}
              </div>
              {openDownloadModal && renderDownloadModal()}
            </div>
          </div>
          {shouldRenderBottomNav() && (
            <AppHeaderBottomNav
              isDesktop={isDesktop}
              isMobile={isMobile}
              cortexNavigations={cortexNavigations}
              handleIsOffline={this.handleIsOffline}
              isOffline={isOffline}
              appHeaderNavigationLinks={appHeaderNavigationLinks}
              brSuggest={brSuggest}
              isInStandaloneMode={isInStandaloneMode}
              isNavExpanded={isNavExpanded}
              toggleNavExpanded={this.toggleNavExpanded}
            />
          )}
          {!isDesktop && (
            <div
              className={`collapsable-container collapse collapsed ${
                isNavExpanded ? "show" : ""
              }`}
            >
              {isLoggedIn && !detectIEBrowser() ? (
                <div className="mobile-user-information-container">
                  {ecommerceUser && (
                    <div className="user-account-info">
                      <h6 className="user-account-num">{company}</h6>
                      <h6 className="user-account-num">
                        {intl.get("account-number")}: {customerNumber}
                      </h6>
                    </div>
                  )}
                  <a
                    href={Config.helpCenter}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="help-center"
                  >
                    <div>{intl.get("help-center")}</div>
                  </a>
                  {ecommerceUser && (
                    <>
                      {shouldDisplayJobDropdown ? (
                        <JobDropdownComponent />
                      ) : null}
                      {Config.showClientInformation &&
                        clientList &&
                        clientList.length &&
                        defaultCart && <ClientDropdownComponent />}

                      {Config.branchDropDownLocation === "header" &&
                      selectedBranch ? (
                        <BranchDropdownComponent
                          selectedBranch={selectedBranch.code}
                          isMobile={!isDesktop}
                        />
                      ) : null}
                    </>
                  )}
                </div>
              ) : null}

              {!detectIEBrowser() && (!isLoggedIn || ecommerceUser) && (
                <div className="mobile-navigation-container">
                  {cortexNavigations ? (
                    <AppHeaderNavigationBarMain
                      isOfflineCheck={this.handleIsOffline}
                      isMobileView
                      appHeaderNavigationLinks={appHeaderNavigationLinks}
                      cortexNavigations={cortexNavigations}
                      toggleMobileMenu={this.toggleMobile}
                    />
                  ) : (
                    ""
                  )}
                </div>
              )}
            </div>
          )}
          {isOffline && !detectIEBrowser() ? (
            <div className="network-offline alert alert-primary fade in">
              <strong className="text-center">
                {intl.get("network-offline")}
              </strong>
            </div>
          ) : (
            ""
          )}
          {isLoggedIn && (userProfile.hasError || cartError || branchError) && (
            <Redirect
              to={{
                pathname: "/maintenance",
                state: {
                  error: {
                    e: {
                      message: userProfile.hasError || cartError || branchError
                    },
                    errIn: "AppHeaderMain.tsx"
                  }
                }
              }}
            />
          )}
          {isLoggedIn && branchesErrorCode && branchesErrorMessage && (
            <Redirect
              to={{
                pathname: "/maintenance",
                state: {
                  error: {
                    e: {
                      message: branchesErrorMessage
                    },
                    errIn: "AppHeaderMain.tsx",
                    errorCode: branchesErrorCode
                  }
                }
              }}
            />
          )}
          {openInvalidUserRightsModal && renderInvalidUserRightsModal()}
          {openRegistrationSuccessfulModal &&
            this.renderRegistrtionSuccessfulModal()}
          {openSsoErrorModal && this.renderSsoErrorModal()}
        </header>
      </HelmetProvider>
    );
  }
}

export default withRouter(AppHeaderMain);
