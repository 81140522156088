/* eslint-disable no-nested-ternary */
/**
 * Copyright © 2019 Elastic Path Software Inc. All rights reserved.
 *
 * This is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This software is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with this license. If not, see
 *
 *     https://www.gnu.org/licenses/
 *
 *
 */
import React from "react";
import * as intl from "react-intl-universal";
import { BrProps } from "@bloomreach/react-sdk";
import {
  RouteComponentProps,
  withRouter,
  NavLink,
  Redirect
} from "react-router-dom";
import {
  CartMain,
  CheckoutSummaryList,
  AddPromotionContainer,
  CartCreate,
  CartClear,
  Messagecontainer,
  page,
  getConfig,
  RecommendedProductsWidget,
  AvailableBranchList,
  cortexFetch
} from "@zilker/store-components";
import { MainContext } from "../contexts/MainContext";
import "./CartPage.less";
import {
  checkTokensExpired,
  pushToMaintenace,
  groupCartItems,
  checkResponse,
  convertUnitOfMeasure,
  formatQuoteAvailability,
  isSpecialAirPurifier
} from "../utils/helpers";
import { getAvailability } from "../services/connectGQLservices";
import { changeBranchAndVendorOnCurrentOrder } from "../services/EpServices";
import { getItemWidget } from "../services/PathwaysAndRecommendationsService";
import {
  ItemWidgetResponse,
  Doc,
  Metadata
} from "../../../models/BloomreachPathsAndRecommResponse";
import "../theme/sharedClasses.less";

interface CartPageProps extends RouteComponentProps, BrProps {
  match: any;
  history: any;
}

interface CartPageState {
  cartData: any;
  // cartsData: any;
  isLoading: boolean;
  isDeletingPromoCode: boolean;
  invalidPermission: boolean;
  // multiCartsAvailable: boolean;
  openModal: boolean;
  // updateCartModal: boolean;
  isCartEmpty: boolean;
  isDesktop: boolean;
  cartOptionsVisible: boolean;
  selectedBranch: any;
  backOrdered: boolean;
  availabilityCheckDone: boolean;
  validQuantity: boolean;
  branchModalOpen: boolean;
  isCartAssociatedToContract: boolean;
  pricingContract: string;
  contractNumber: string;
  groupedItems: Array<any>;
  recommendedProducts: Array<Doc>;
  widgetMetaData: Metadata;
  hasQuantityError: boolean;
  invalidPromoCode: string;
  inventoryError: string;
  isErrorMessage: boolean;
}

let Config;

class CartPage extends React.Component<CartPageProps, CartPageState> {
  static contextType = MainContext;

  cartItemsSection: React.RefObject<HTMLDivElement>;

  _isMounted = false;

  constructor(props) {
    super(props);
    Config = getConfig().config;
    this.state = {
      cartData: undefined,
      isLoading: false,
      isDeletingPromoCode: false,
      invalidPermission: false,
      openModal: false,
      isCartEmpty: false,
      isDesktop: false,
      cartOptionsVisible: false,
      selectedBranch: undefined,
      backOrdered: false,
      availabilityCheckDone: false,
      validQuantity: true,
      branchModalOpen: false,
      isCartAssociatedToContract: false,
      pricingContract: "",
      contractNumber: "",
      groupedItems: [],
      recommendedProducts: [],
      widgetMetaData: null,
      hasQuantityError: false,
      invalidPromoCode: "",
      inventoryError: "",
      isErrorMessage: false
    };
    this.cartItemsSection = React.createRef();

    this.handleModalOpen = this.handleModalOpen.bind(this);
    this.handleModalClose = this.handleModalClose.bind(this);
    this.handleCartSelect = this.handleCartSelect.bind(this);
    this.fetchCartData = this.fetchCartData.bind(this);
    this.updatePredicate = this.updatePredicate.bind(this);
    this.handleBranchModalOpen = this.handleBranchModalOpen.bind(this);
    this.changeBranch = this.changeBranch.bind(this);
    this.handleFailedAvailability = this.handleFailedAvailability.bind(this);
    this.setItemsWithErrors = this.setItemsWithErrors.bind(this);
    this.scrollToErrorSection = this.scrollToErrorSection.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    const {
      branches: { findHomeBranch, branchesList },
      account: {
        accountDetails: { homeBranch }
      }
    } = this.context;
    if (branchesList) {
      findHomeBranch(homeBranch, branchesList);
    }
    this.updatePredicate();
    window.addEventListener("resize", this.updatePredicate);
    this.fetchCartData();
    this.setState({
      isLoading: false,
      isDeletingPromoCode: false
    });
    page();
  }

  componentDidUpdate() {
    const {
      branches: { findHomeBranch, branchesList },
      account: {
        accountDetails: { homeBranch }
      }
    } = this.context;

    if (branchesList) {
      findHomeBranch(homeBranch, branchesList);
    }

    this.fetchCartData();
  }

  componentWillUnmount() {
    this._isMounted = false;
    window.removeEventListener("resize", this.updatePredicate);
  }

  updatePredicate() {
    if (this._isMounted) {
      this.setState({
        isDesktop: window.innerWidth >= 768
      });
    }
  }

  fetchCartData() {
    const { cartData } = this.state;
    const {
      cart: { cartDetails, setCartDetails },
      branches: { findBranch, branchesList }
    } = this.context;

    if (cartDetails && branchesList) {
      const { defaultCart, isCartUpdated } = cartDetails;
      if (((!cartData && defaultCart) || isCartUpdated) && this._isMounted) {
        const selectedBranch = findBranch(defaultCart.selectedBranch.code);
        const { cartOrderDetailsForm, items } = defaultCart;
        const groupedItems = groupCartItems(items, true);
        this.setState({
          cartData: defaultCart,
          selectedBranch,
          isCartEmpty: !defaultCart.totalQuantity,
          isCartAssociatedToContract:
            items && items.length && cartOrderDetailsForm["contract-number"],
          pricingContract: cartOrderDetailsForm.pricing,
          contractNumber: cartOrderDetailsForm["contract-number"],
          groupedItems,
          isDeletingPromoCode: false
        });
        this.validateCartAvailability(defaultCart);
        this.fetchRecommendedProducts(items);
        setCartDetails(prevState => ({
          ...prevState,
          isCartUpdated: false
        }));
      }
    }
  }

  async fetchRecommendedProducts(items) {
    const {
      location: { state = {} }
    } = this.props;
    const {
      branches: { branchesList },
      account: {
        accountDetails: { membership }
      }
    } = this.context;

    if (!items || !items.length) {
      this.setState({
        recommendedProducts: [],
        widgetMetaData: null
      });
      return;
    }

    const itemIds = items.map(item => item._item[0]._code[0].code);
    const branchIds = branchesList.map(branch =>
      "".concat('"', branch.branchNumber.toString(), '"')
    );

    try {
      const type = "recommendedProductsCart";
      const { data }: { data: ItemWidgetResponse } = await getItemWidget(
        itemIds,
        window.location.href,
        state.prevUrl,
        membership,
        type,
        branchIds
      );
      const {
        response: { docs },
        metadata
      } = data;
      this.setState({
        recommendedProducts: docs,
        widgetMetaData: metadata
      });
    } catch (error) {
      console.error(error);
      this.setState({
        recommendedProducts: [],
        widgetMetaData: null
      });
    }
  }

  /**
   *
   * @desc This function handles the failed availability response. See DGE-3133.
   * If there are no available information - branch availability value
   * is hardcoded to 0, so "Call for availability" message will be displayed to user.
   * Also, the unit of measure data is missing - so the value is hardcoded to the default, "1".
   * The "backOrdered" value in state controls if the backordered button for opening branch modal, is
   * going to be visible.
   */
  handleFailedAvailability(defaultCart) {
    /* eslint-disable no-param-reassign */
    if (defaultCart.items) {
      const cartItems = [];
      const backOrderderedArray = defaultCart.items.map(cartItem => {
        cartItem.branchAvailability = 0;
        cartItem.regionAvailability = 0;

        cartItem.unitOfMeasure = 1;
        cartItem.warrningMessage = "";

        cartItems.push(cartItem);

        return false;
      });

      this.setState({
        backOrdered: backOrderderedArray.includes(true),
        availabilityCheckDone: true,
        cartData: { ...defaultCart, items: cartItems },
        groupedItems: groupCartItems(cartItems, true),
        validQuantity: true
      });
    }
  }

  formatCartItemsWithAvailability(inventoryAvailability) {
    const { cartData: defaultCart } = this.state;
    const cartItems = [];
    const backOrderedArray =
      defaultCart && defaultCart.items
        ? defaultCart.items.map(item => {
            const inventoryItem = inventoryAvailability.find(
              ia =>
                ia.sku === item._item[0]._code[0].code &&
                ia.branchNumber === item["branch-number"]
            );
            const unitOfMeasureNum =
              inventoryItem && Number(inventoryItem.unitOfMeasure);
            const convertedUnitOfMeasure =
              inventoryItem &&
              convertUnitOfMeasure(unitOfMeasureNum, item.quantity);

            const doesSkuHaveRegionAvailability = inventoryAvailability.find(
              ia => ia.sku === item._item[0]._code[0].code
            );
            const cartItem = inventoryItem
              ? {
                  ...item,
                  branchAvailability: inventoryItem.branchAvailability,
                  regionAvailability: inventoryItem.regionAvailability,
                  dcAvailability: inventoryItem.dcQtyAvailable,
                  unitOfMeasure: unitOfMeasureNum,
                  warrningMessage:
                    convertedUnitOfMeasure !== 0
                      ? intl.get("unit-of-measure-error-message", {
                          product: intl.get("product"),
                          unitOfMeasure: inventoryItem.unitOfMeasure
                        })
                      : ""
                }
              : {
                  ...item,
                  branchAvailability: 0,
                  regionAvailability:
                    doesSkuHaveRegionAvailability &&
                    doesSkuHaveRegionAvailability.regionAvailability
                      ? doesSkuHaveRegionAvailability.regionAvailability
                      : 0,
                  unitOfMeasure: 1,
                  warningMessage: ""
                };

            cartItems.push(cartItem);

            return cartItem.branchAvailability < cartItem.quantity;
          })
        : [];
    this.setState({
      backOrdered: backOrderedArray.includes(true),
      availabilityCheckDone: true,
      cartData: { ...defaultCart, items: cartItems },
      groupedItems: groupCartItems(cartItems, true),
      validQuantity: cartItems.every(cartItem => !cartItem.warrningMessage)
    });
  }

  validateCartAvailability(defaultCart) {
    const { history } = this.props;
    const {
      auth: { logout },
      account: {
        accountDetails: { customerNumber }
      }
    } = this.context;
    const skus = defaultCart.items
      ? defaultCart.items.map(item => item._item[0]._code[0].code)
      : [];
    const { selectedBranch } = defaultCart;
    if (!skus.length) {
      this.setState({
        cartData: defaultCart,
        groupedItems: []
      });
      return;
    }
    if (customerNumber && selectedBranch && skus) {
      const branchNumber = selectedBranch.code;
      getAvailability(skus, customerNumber, branchNumber)
        .then(res => {
          if (
            res &&
            res.data &&
            res.data.data &&
            res.data.data.inventory &&
            res.data.data.inventory.entitledInventory
          ) {
            const data = res.data.data.inventory.entitledInventory;
            const availability = formatQuoteAvailability(
              data.branches,
              data.regionRollups,
              skus,
              data.hubInventory
            );
            this.formatCartItemsWithAvailability(availability);
          }
        })
        .catch(e => {
          console.error("Inventory error", e);
          this.setState({
            availabilityCheckDone: true,
            inventoryError: intl.get("inventory-error")
          });
          if (checkTokensExpired(e)) {
            logout().catch(err =>
              pushToMaintenace(history, {
                e: err,
                errIn: "Logout => validateCartAvailability => CartPage.tsx"
              })
            );
          }
        });
    }
  }

  checkout() {
    const { history } = this.props;
    if (
      localStorage.getItem(`${Config.cortexApi.scope}_oAuthRole`) ===
      "REGISTERED"
    ) {
      history.push("/checkout");
    } else {
      history.push("/signIn");
    }
  }

  // eslint-disable-next-line class-methods-use-this
  scrollToErrorSection(ref) {
    ref.current.scrollIntoView({ behavior: "smooth" });
  }

  deletePromoCode(promotionCode) {
    const {
      cartData: { cartOrderDetailsForm }
    } = this.state;
    const { history } = this.props;
    const {
      cart: { getCartDetails },
      auth: { logout }
    } = this.context;

    const couponFormLink = cartOrderDetailsForm.links[0].uri;

    this.setState({
      isDeletingPromoCode: true
    });

    cortexFetch(couponFormLink, {
      method: "post",
      body: JSON.stringify({
        "daikin-promo-codes": promotionCode,
        "update-mode": "PROMO_CODES_REMOVE"
      })
    })
      .then(res => {
        const onSuccess = data => data;
        return checkResponse(res, onSuccess);
      })
      .then(() => {
        this.setState({
          invalidPromoCode: ""
        });
        return getCartDetails();
      })
      .then(() => this.fetchCartData())
      .catch(e => {
        this.setState({
          isDeletingPromoCode: false
        });
        if (checkTokensExpired(e)) {
          logout().catch(err =>
            pushToMaintenace(history, {
              e: err,
              errIn: "Logout => deletePromoCode => CartPage.tsx"
            })
          );
        }
      });
  }

  openPromoCodeErrorModal() {
    const { invalidPromoCode } = this.state;
    const {
      modal: { warningModal, setWarningModal }
    } = this.context;
    if (!warningModal.openModal && invalidPromoCode) {
      setWarningModal({
        openModal: true,
        modalMessage: intl.get("invalid-promo-codes-warning", {
          PromoCode: invalidPromoCode
        })
      });
      this.deletePromoCode(invalidPromoCode);
    }
  }

  validatePromoCodes() {
    const { history } = this.props;
    const {
      cart: { cartDetails },
      auth: { logout }
    } = this.context;

    if (cartDetails) {
      const { defaultCart } = cartDetails;
      const cartUri = `/validatedaikinpromocodes${defaultCart.selfUri}/validatedaikinpromocodes/form`;

      cortexFetch(cartUri, {
        method: "post"
      })
        .then(res => (res.ok ? res : res.json()))
        .then(res => {
          const onSuccess = data => data;
          return checkResponse(res, onSuccess);
        })
        .then(res => {
          this.checkout();
        })
        .catch(e => {
          if (e.messages && e.messages[0].id === "request.invalid.promocode") {
            this.setState({
              invalidPromoCode: e.messages[0].data.promoCodes
            });
            this.openPromoCodeErrorModal();
          }
          if (checkTokensExpired(e)) {
            logout().catch(err =>
              pushToMaintenace(history, {
                e: err,
                errIn: "Logout => validatePromoCodes => CartPage.tsx"
              })
            );
          }
        });
    }
  }

  validateCheckout(notSelectedPickupBranches, missingFulfillmentMethod) {
    if (notSelectedPickupBranches.length || missingFulfillmentMethod.length) {
      this.scrollToErrorSection(this.cartItemsSection);
      return this.setState({ isErrorMessage: true });
    }

    const {
      cartData: { cartOrderDetailsForm }
    } = this.state;

    const appliedPromoCodes = cartOrderDetailsForm["daikin-promo-codes"]
      ? cartOrderDetailsForm["daikin-promo-codes"].split("|")
      : [];

    if (appliedPromoCodes.length) {
      this.validatePromoCodes();
    }
    return this.checkout();
  }

  handleModalOpen() {
    if (this._isMounted) {
      this.setState({
        openModal: true
        // updateCartModal: false
      });
    }
  }

  handleModalClose() {
    if (this._isMounted) {
      this.setState({
        cartData: undefined,
        openModal: false
      });
    }
  }

  handleCartSelect(cartData) {
    if (this._isMounted) {
      this.setState({ cartData });
    }
  }

  checkPermissions() {
    const { invalidPermission } = this.state;
    if (Config.b2b.enable && invalidPermission) {
      return (
        <div className="message-permission">
          <h2>{intl.get("permission-message")}</h2>
        </div>
      );
    }
    return null;
  }

  renderWarningMessage() {
    const { cartData, inventoryError } = this.state;
    const hasError =
      cartData &&
      cartData.warningMessage &&
      cartData.warningMessage.type === "warning";

    const openChat = () => window.zE.activate();

    if (hasError) {
      return (
        <Messagecontainer
          message={{
            type: "danger-message",
            debugMessages: intl.get("price-calculation-error"),
            isMsgEmphasized: true
          }}
          closeContainerHandler={null}
          button={{
            text: intl.get("support"),
            action: openChat,
            className: "support-btn"
          }}
        />
      );
    }
    if (inventoryError) {
      return (
        <Messagecontainer
          message={{
            type: "basic",
            debugMessages: inventoryError
          }}
          closeContainerHandler={null}
        />
      );
    }
    return null;
  }

  handleBranchModalOpen(branchModalOpen) {
    this.setState({
      branchModalOpen
    });
  }

  changeBranch(
    branchNumber,
    closeModal,
    notSelectedPickupBranches?,
    missingFulfillmentMethod?
  ) {
    const { history } = this.props;
    const {
      cartData: { cartOrderDetailsForm }
    } = this.state;
    const {
      cart: { getCartDetails },
      auth: { logout },
      branches: { findBranch }
    } = this.context;
    const branch = findBranch(branchNumber);
    const fetchLink = cartOrderDetailsForm.links[0].uri;

    changeBranchAndVendorOnCurrentOrder(branch, fetchLink)
      .then(() => {
        return getCartDetails();
      })
      .then(() => {
        if (closeModal) {
          closeModal();
          this.handleBranchModalOpen(false);
        }
        sessionStorage.setItem("notified-branch", "true");
      })
      .then(() => {
        if (notSelectedPickupBranches && missingFulfillmentMethod) {
          this.validateCheckout(
            notSelectedPickupBranches,
            missingFulfillmentMethod
          );
        }
      })
      .catch((e: any) => {
        if (checkTokensExpired(e)) {
          logout().catch(err =>
            pushToMaintenace(history, {
              e: err,
              errIn: "Logout => changeBranch => CartPage.tsx"
            })
          );
        } else {
          pushToMaintenace(history, {
            e,
            errIn: "changeBranch => CartPage.tsx"
          });
        }
      });
  }

  setItemsWithErrors(itemWithError) {
    const { cartData } = this.state;
    const { items } = cartData;
    const itemsWithError = items.map(item =>
      item._item[0]._code[0].code === itemWithError._item[0]._code[0].code
        ? itemWithError
        : item
    );
    this.setState({
      cartData: { ...cartData, items: itemsWithError },
      groupedItems: groupCartItems(itemsWithError, true)
    });
  }

  checkCartItems(notSelectedPickupBranches, missingFulfillmentMethod) {
    const { cartData } = this.state;
    const { items } = cartData;
    const {
      context: { cart }
    } = this;
    const {
      cartDetails: { defaultCart }
    } = cart;

    const deliveryItems = items.filter(
      item => item["shipping-method"] === "delivery"
    );
    // only pick up items, proceed to checkout
    if (deliveryItems.length === 0) {
      this.validateCheckout(
        notSelectedPickupBranches,
        missingFulfillmentMethod
      );
    } else {
      // check if any delivery item does not have the user's current selected branch
      const itemToBeUpdated = deliveryItems.find(
        item =>
          defaultCart &&
          defaultCart.selectedBranch &&
          item["branch-number"] !== defaultCart.selectedBranch.code &&
          !isSpecialAirPurifier(item._item[0]._code[0].code)
      );
      // if true, call change branch so delivery items in cart are all updated to user's current selected branch
      if (itemToBeUpdated) {
        this.changeBranch(
          defaultCart.selectedBranch.code,
          undefined,
          notSelectedPickupBranches,
          missingFulfillmentMethod
        );
      } else {
        // otherwise, proceed to checkout
        this.validateCheckout(
          notSelectedPickupBranches,
          missingFulfillmentMethod
        );
      }
    }
  }

  render() {
    const {
      cartData,
      isLoading,
      isDeletingPromoCode,
      openModal,
      isCartEmpty,
      isDesktop,
      cartOptionsVisible,
      selectedBranch,
      availabilityCheckDone,
      validQuantity,
      isCartAssociatedToContract,
      pricingContract,
      contractNumber,
      groupedItems,
      recommendedProducts,
      widgetMetaData,
      branchModalOpen,
      hasQuantityError,
      backOrdered,
      inventoryError,
      isErrorMessage
    } = this.state;
    const { history } = this.props;
    const {
      context: { cart, auth, branches }
    } = this;
    const {
      cartDetails: { defaultCart }
    } = cart;
    let items = [];
    let notSelectedPickupBranches = [];
    let missingFulfillmentMethod = [];
    let clientId = "";
    if (defaultCart && !cartData) {
      ({ clientId } = defaultCart);
    } else if (cartData) {
      ({ clientId } = cartData);
    }

    if (cartData && cartData.items) {
      items = cartData.items.map(item => ({
        sku: item._item[0]._code[0].code,
        quantity: item.quantity
      }));

      missingFulfillmentMethod = cartData.items
        .filter(branch => !branch["shipping-method"])
        .map(filteredBranch => {
          if (filteredBranch["ahri-number"]) {
            return {
              ahriNumber: `${filteredBranch["ahri-number"]}-${filteredBranch["shipping-method"]}-${filteredBranch["branch-number"]}`
            };
          }
          return {
            sku: `${filteredBranch._item[0]._code[0].code}_${filteredBranch["shipping-method"]}_${filteredBranch["branch-number"]}`
          };
        });

      notSelectedPickupBranches = cartData.items
        .filter(
          branch =>
            branch["shipping-method"] === "pickup" && !branch["branch-number"]
        )
        .map(filteredBranch => {
          if (filteredBranch["ahri-number"]) {
            return {
              ahriNumber: `${filteredBranch["ahri-number"]}-${filteredBranch["shipping-method"]}-${filteredBranch["branch-number"]}`
            };
          }
          return {
            sku: `${filteredBranch._item[0]._code[0].code}_${filteredBranch["shipping-method"]}_${filteredBranch["branch-number"]}`
          };
        });
    }

    const { cartError } = cart;
    const { branchesErrorCode, branchesErrorMessage } = branches;
    const itemDetailLink = "/itemdetail";
    const cartName = intl.get("default");

    const jobNumber =
      cartData && cartData.default === "true" ? cartData.jobNumber : null;
    const jobName =
      cartData && cartData.default === "true" ? cartData.jobName : null;

    // PGL-1273: Currently not using this logic, might be returned
    // const useWarningMessage =
    //   backOrdered &&
    //   !isCartEmpty &&
    //   (!isCartAssociatedToContract ||
    //     (isCartAssociatedToContract && pricingContract.toUpperCase() === "Y"));

    if (cartError) {
      return (
        <Redirect
          to={{
            pathname: "/maintenance",
            state: {
              error: {
                e: { message: cartError },
                errIn: "cart context error => CartPage.tsx"
              }
            }
          }}
        />
      );
    }
    if (branchesErrorMessage) {
      return (
        <Redirect
          to={{
            pathname: "/maintenance",
            state: {
              error: {
                e: {
                  message: branchesErrorMessage
                },
                errIn: "branches error => CartPage.tsx",
                errorCode: branchesErrorCode
              }
            }
          }}
        />
      );
    }

    return (
      <>
        <div className="cart-container-new container-fluid d-flex flex-column flex-grow-1">
          <div className="container d-flex flex-column flex-grow-1">
            <div className="row flex-grow-1">
              <div className="col-12 d-flex flex-xl-row flex-column">
                {cartData && !isLoading ? (
                  <div className="cart-body flex-fill">
                    <div className="messages-container">
                      {Config.cortexApi.scope !== "motili" && (
                        <AvailableBranchList
                          branchModalOpen={branchModalOpen}
                          selectedBranch={selectedBranch}
                          clientId={clientId}
                          items={items}
                          history={history}
                          handleBranchModalOpen={this.handleBranchModalOpen}
                          changeBranch={this.changeBranch}
                          // useWarningMessage={useWarningMessage}
                        />
                      )}
                      {this.renderWarningMessage()}
                    </div>
                    <h1 className="section-title d-flex override-h1-as-h4">
                      <span>
                        {// Uncomment if multicart logic is needed here.
                        // As per DGE-3116, the cart list is not fetched in cart page anymore.
                        // The number of available carts is not visible from cart page.
                        // multiCartsAvailable
                        // ?
                        `${cartName} ${intl.get("cart")}`
                        // : `${intl.get("shopping-cart")} (${
                        // cartData["total-quantity"]
                        // should be:
                        // cartData.totalQuantity
                        // })`
                        }
                      </span>
                      {jobNumber && jobName && (
                        <span className="flex-fill job-account-div">
                          {`${jobNumber} ${jobName}`}
                        </span>
                      )}
                      {contractNumber && !isCartEmpty && (
                        <span className="flex-fill contract-number-div">
                          {contractNumber}
                        </span>
                      )}
                      {/* cartsData && !isLoading && multiCartsAvailable && ( */}
                      {!isLoading && (
                        <div className="d-flex multi-cart-container justify-content-between">
                          {openModal && (
                            <CartCreate
                              handleModalClose={this.handleModalClose}
                              openModal={openModal}
                              // updateCartModal={updateCartModal}
                              history={history}
                              auth={auth}
                            />
                          )}
                          <button
                            type="button"
                            aria-label="add or remove from cart"
                            className="d-md-none d-inline-block mobile-options-toggle"
                            onClick={() => {
                              if (this._isMounted) {
                                this.setState({
                                  cartOptionsVisible: !cartOptionsVisible
                                });
                              }
                            }}
                          >
                            {cartOptionsVisible ? (
                              <i className="icon-minus" />
                            ) : (
                              <i className="icon-plus" />
                            )}
                          </button>
                        </div>
                      )}
                    </h1>
                    <div className="cart-menu-container d-flex justify-content-between align-items-left align-items-md-center flex-column flex-md-row">
                      {(cartOptionsVisible || isDesktop) && (
                        <>
                          <div className="cart-menu d-flex align-items-left align-items-md-center flex-column flex-md-row">
                            <div
                              className={`divider ${
                                isCartAssociatedToContract ? "disabled" : ""
                              }`}
                            >
                              <button
                                className="btn-ellipsis"
                                type="button"
                                onClick={this.handleModalOpen}
                                disabled={
                                  isCartEmpty || isCartAssociatedToContract
                                }
                              >
                                {intl.get("save-order")}
                              </button>
                            </div>
                            <div
                              className={`divider ${
                                isCartAssociatedToContract ? "disabled" : ""
                              }`}
                            >
                              <NavLink
                                to="/myAccount/savedOrders"
                                onClick={e => {
                                  if (isCartAssociatedToContract) {
                                    e.preventDefault();
                                  }
                                }}
                              >
                                {intl.get("add-a-saved-order")}
                              </NavLink>
                            </div>
                            <div
                              className={`divider ${
                                isCartAssociatedToContract &&
                                pricingContract.toUpperCase() === "N"
                                  ? "disabled"
                                  : ""
                              }`}
                            >
                              <NavLink
                                to="/myAccount/branchSettings"
                                onClick={e => {
                                  if (
                                    isCartAssociatedToContract &&
                                    pricingContract.toUpperCase() === "N"
                                  ) {
                                    e.preventDefault();
                                  }
                                }}
                              >
                                {intl.get("change-branch")}
                              </NavLink>
                            </div>
                            <CartClear
                              cartData={cartData}
                              isCartEmpty={isCartEmpty}
                              history={history}
                              auth={auth}
                            />
                          </div>
                        </>
                      )}
                    </div>
                    {cartData && !isLoading && (
                      <div
                        data-region="mainCartRegion"
                        className="cart-main-container"
                        style={{ display: "block" }}
                        ref={this.cartItemsSection}
                      >
                        <CartMain
                          empty={
                            !cartData.totalQuantity || !cartData.items.length
                          }
                          cartData={cartData}
                          itemDetailLink={itemDetailLink}
                          groupedItems={groupedItems}
                          setItemError={this.setItemsWithErrors}
                          inventoryError={inventoryError}
                          notSelectedPickupBranches={notSelectedPickupBranches}
                          missingFulfillmentMethod={missingFulfillmentMethod}
                          isErrorMessage={isErrorMessage}
                        />
                        {Config.showRecommendedProducts &&
                        recommendedProducts &&
                        recommendedProducts.length ? (
                          <RecommendedProductsWidget
                            products={recommendedProducts}
                            metadata={widgetMetaData}
                          />
                        ) : null}
                      </div>
                    )}
                  </div>
                ) : (
                  <div className="loader-container">
                    <div className="loader" />
                  </div>
                )}
                {cartData && !isLoading && (
                  <div className="cart-sidebar">
                    <div className="cart-sidebar-sticky">
                      <h2 className="section-title">
                        <span>{intl.get("order-summary")}</span>
                      </h2>
                      <>
                        {Config.enablePromotion && (
                          <AddPromotionContainer
                            data={cartData}
                            onSubmittedPromotion={() => {
                              this.fetchCartData();
                            }}
                            history={history}
                            auth={auth}
                            isDeletingPromoCode={isDeletingPromoCode}
                          />
                        )}
                        <CheckoutSummaryList
                          data={cartData}
                          onChange={() => {
                            this.fetchCartData();
                          }}
                          history={history}
                          auth={auth}
                        />
                        {Config.calculatePrice && (
                          <div className="estimated-container d-flex align-items-center">
                            <div className="flex-fill summary-label">
                              {intl.get("estimated-total")}:
                            </div>
                            <div>{cartData.total.display}</div>
                          </div>
                        )}
                        <div className="checkot-button-container">
                          <button
                            className=" dast-btn wide dast-btn-primary"
                            aria-label={intl.get("proceed-to-checkout")}
                            disabled={
                              !cartData.total.amount ||
                              !cartData.totalQuantity ||
                              !availabilityCheckDone ||
                              isDeletingPromoCode ||
                              !validQuantity ||
                              (cartData.warningMessage &&
                                cartData.warningMessage.type === "warning") ||
                              cartData.items.some(item => item.error) ||
                              hasQuantityError
                            }
                            type="button"
                            onClick={() => {
                              this.checkCartItems(
                                notSelectedPickupBranches,
                                missingFulfillmentMethod
                              );
                            }}
                          >
                            {intl.get("proceed-to-checkout")}
                          </button>
                          {!availabilityCheckDone && !validQuantity && (
                            <div className="miniLoader" />
                          )}
                        </div>
                        {Config.calculatePrice ? (
                          <p className="price-disclaimer">
                            *
                            {intl.get(
                              "commodity-prices-may-vary-at-final-checkout"
                            )}
                          </p>
                        ) : null}
                        {!missingFulfillmentMethod.length &&
                        notSelectedPickupBranches.length &&
                        isErrorMessage ? (
                          <div className="cart-error-message">
                            <Messagecontainer
                              message={{
                                type: "danger-message",
                                debugMessages:
                                  notSelectedPickupBranches.length > 1
                                    ? intl.get(
                                        "please-select-a-pickup-branch-for-all-highlighted-items"
                                      )
                                    : intl.get(
                                        "please-select-a-pickup-branch-for",
                                        {
                                          skuCode: notSelectedPickupBranches[0]
                                            .sku
                                            ? notSelectedPickupBranches[0].sku.split(
                                                "_"
                                              )[0]
                                            : notSelectedPickupBranches[0].ahriNumber.split(
                                                "-"
                                              )[0]
                                        }
                                      )
                              }}
                              closeContainerHandler={null}
                              stayOpen
                            />
                          </div>
                        ) : null}
                        {missingFulfillmentMethod.length && isErrorMessage ? (
                          <div className="cart-error-message">
                            <Messagecontainer
                              message={{
                                type: "danger-message",
                                debugMessages:
                                  missingFulfillmentMethod.length > 1
                                    ? intl.get(
                                        "select-fulfillment-method-for-items"
                                      )
                                    : intl.get(
                                        "select-fulfillment-method-for",
                                        {
                                          skuCode: missingFulfillmentMethod[0]
                                            .sku
                                            ? missingFulfillmentMethod[0].sku.split(
                                                "_"
                                              )[0]
                                            : missingFulfillmentMethod[0].ahriNumber.split(
                                                "-"
                                              )[0]
                                        }
                                      )
                              }}
                              closeContainerHandler={null}
                              stayOpen
                            />
                          </div>
                        ) : null}
                      </>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="checkPermissionsContainer">
              {this.checkPermissions()}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter<CartPageProps, any>(CartPage);
