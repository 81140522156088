import React, { useState, useEffect } from "react";
import intl from "react-intl-universal";
import { cortexFetch, zoom, getConfig } from "@zilker/store-components";
import * as LoginService from "@elasticpath/ref-store/src/services/LoginService";
import {
  checkTokensExpired,
  checkResponse,
  formatCartOrderDetails
} from "../utils/helpers";

// eslint-disable-next-line import/prefer-default-export
export const CartContext = () => {
  const initialCartDetails = {
    defaultCart: undefined,
    totalCount: 0,
    isCartUpdated: false,
    cartDataFetched: false
  };
  const initialOrderInformation = {
    // "alternate-email-address": "",
    "date-needed": "",
    "order-comments": "",
    "po-number": "",
    "shipping-instructions": "",
    date: "",
    time: "",
    "ship-complete": false,
    "show-pick-tickets-price": false,
    phone: ""
  };

  const initialOrderInformationErrors = {
    "po-number": "",
    date: "",
    time: "",
    phone: ""
  };

  const defaultClient = {
    name: "",
    id: ""
  };

  const { config } = getConfig();

  const accountSharedId = localStorage.getItem(
    `${config.cortexApi.scope}_AccountSharedId`
  );

  const cartDataLocalStorage = localStorage.getItem("orgAuthData")
    ? JSON.parse(localStorage.getItem("orgAuthData"))
    : null;

  const [currentCount, setCurrentCount] = useState({
    type: "",
    message: "",
    count: 0,
    show: false
  });
  const [cartError, setCartError] = useState(false);
  const [cartDetails, setCartDetails] = useState(initialCartDetails);
  const [cartList, setCartList] = useState(null);
  const [cartListError, setCartListError] = useState(false);
  const checkLogin = LoginService.isLoggedIn();
  const authToken = localStorage.getItem(
    `${config.cortexApi.scope}_oAuthToken`
  );
  const [isCartFetchReady, setIsCartFetchReady] = useState(false);
  const [cartDataFromAuth, setCartDataFromAuth] = useState(
    cartDataLocalStorage
  );
  const [selectedCart, setSelectedCart] = useState(null);
  const [selectedPayment, setSelectedPayment] = useState("");
  const [selectedPaymentUri, setSelectedPaymentUri] = useState("");
  const [cartOrderDetails, setCartOrderDetails] = useState(undefined);
  const [orderInformation, setOrderInformation] = useState(
    initialOrderInformation
  );
  const [selectedClient, setSelectedClient] = useState(defaultClient);
  const [notificationsStatus, setNotificationsStatus] = useState(undefined);
  const [selectedPaymentMethodError, setSelectedPaymentMethodError] = useState(
    ""
  );
  const [notificationsData, setNotificationsData] = useState(undefined);
  const [cashUserOnlyError, setCashUserOnlyError] = useState("");
  const [
    selectedDeliveryMethodError,
    setSelectedDeliveryMethodError
  ] = useState("");
  const [shippingOptions, setShippingOptions] = useState(null);
  const [selectedShippingOption, setSelectedShippingOption] = useState(null);
  const [selectedShippingAddress, setSelectedShippingAddress] = useState(null);
  const [shippingAddresses, setShippingAddresses] = useState(null);
  const [pendingShippingAddress, setPendingShippingAddress] = useState(null);
  const [orderInformationErrors, setOrderInformationErrors] = useState(
    initialOrderInformationErrors
  );

  const {
    cortexApi: { scope }
  } = config;

  function storeSelectedCart(cartNumber) {
    setSelectedCart(cartNumber);
    localStorage.setItem("selectedCart", cartNumber);
  }

  /**
   * ## checkCartWarning
   *
   * @param cart any
   *
   * @description Function that maps cart prices to display "pending" if cart has warning message
   */

  function checkCartWarning(cart) {
    const cartWithWarning = { ...cart };

    if (
      cart.messages &&
      cart.messages[0] &&
      cart.messages[0].type === "warning"
    ) {
      const priceWithWarning = {
        display: intl.get("pending"),
        amount: intl.get("pending"),
        currency: ""
      };

      if (cartWithWarning._total) {
        cartWithWarning._total[0] = {
          ...cartWithWarning._total[0],
          cost: [priceWithWarning]
        };
      }
      if (cartWithWarning._discount) {
        cartWithWarning._discount[0] = {
          ...cartWithWarning._discount[0],
          discount: [priceWithWarning]
        };
      }
      if (cartWithWarning._order) {
        const cartOrder = cartWithWarning._order[0];
        cartWithWarning._order[0] = { ...cartOrder };
        if (cartOrder._total) {
          cartWithWarning._order[0]._total = [
            { ...cartOrder._total[0], cost: [priceWithWarning] }
          ];
        }
        if (cartOrder._tax) {
          cartWithWarning._order[0]._tax = [
            {
              ...cartOrder._tax[0],
              cost: [priceWithWarning],
              total: priceWithWarning
            }
          ];
        }
        if (cartOrder["_other-charges"]) {
          cartWithWarning._order[0]["_other-charges"] = [
            { ...cartOrder["_other-charges"][0], cost: [priceWithWarning] }
          ];
        }
      }
      if (cartWithWarning._lineitems) {
        const itemsWithWarning =
          cartWithWarning._lineitems[0]._element &&
          cartWithWarning._lineitems[0]._element.map(item => {
            const itemPrice = item._price ? item._price[0] : {};
            const itemTotal = item._total ? item._total[0] : {};
            return {
              ...item,
              _price: [
                {
                  ...itemPrice,
                  "purchase-price": [priceWithWarning],
                  "list-price": [priceWithWarning]
                }
              ],
              _total: [{ ...itemTotal, cost: [priceWithWarning] }]
            };
          });

        if (itemsWithWarning)
          cartWithWarning._lineitems[0]._element = itemsWithWarning;
      }
    }
    return cartWithWarning;
  }

  function populateCartDetails(response) {
    // If pricing or entitlement services fail on EP we are getting warning message in cart response (for every cart)
    // We will show "pending" instead of the price and allow user to add product to cart, but block the checkout flow

    const defaultCartResponse = checkCartWarning(response._defaultcart[0]);

    if (Object.keys(defaultCartResponse).length) {
      const cartId = defaultCartResponse.self.uri.split("/")[3];

      const defaultCart = {
        default: defaultCartResponse._descriptor[0].default,
        name: defaultCartResponse._descriptor[0].name,
        order: defaultCartResponse._order,
        couponInfoForm:
          defaultCartResponse._order[0]._couponinfo[0]._couponform[0],
        items: defaultCartResponse._lineitems[0]._element,
        totalQuantity: defaultCartResponse["total-quantity"],
        selfUri: defaultCartResponse.self.uri,
        addItemsToCart: defaultCartResponse._additemstocartform[0],
        cartOrderDetailsForm:
          defaultCartResponse._order[0]._cartorderdetails[0]
            ._cartorderdetailsform[0],
        orderUri: defaultCartResponse.links.find(link => link.rel === "order")
          .uri,
        selectedBranch: {
          code:
            defaultCartResponse._order[0]._cartorderdetails[0]
              ._cartorderdetailsform[0]["branch-number"],
          vendor:
            defaultCartResponse._order[0]._cartorderdetails[0]
              ._cartorderdetailsform[0]["branch-vendor"] || "GOODMAN"
        },
        cartId,
        jobName:
          defaultCartResponse._order[0]._cartorderdetails[0]
            ._cartorderdetailsform[0]["job-name"],
        jobNumber:
          defaultCartResponse._order[0]._cartorderdetails[0]
            ._cartorderdetailsform[0]["job-number"],
        warningMessage:
          defaultCartResponse.messages && defaultCartResponse.messages[0],
        clientId:
          defaultCartResponse._order[0]._cartorderdetails[0]
            ._cartorderdetailsform[0]["client-id"],
        clientName:
          defaultCartResponse._order[0]._cartorderdetails[0]
            ._cartorderdetailsform[0]["client-name"]
      };
      try {
        // eslint-disable-next-line prefer-destructuring
        defaultCart.total = defaultCartResponse._total[0].cost[0];
        defaultCart.discount =
          defaultCartResponse._discount[0].discount[0].display;
        // eslint-disable-next-line prefer-destructuring
        defaultCart.orderCost = defaultCartResponse._order[0]._total[0].cost[0];
        defaultCart.orderTax = defaultCartResponse._order[0]._tax[0].total;
        defaultCart.otherCharges =
          defaultCartResponse._order[0]["_other-charges"][0].other;
      } catch (error) {
        console.error(error);
        defaultCart.total = { amount: 0, currency: "USD", display: "$0.00" };
        defaultCart.discount = 0;
        defaultCart.orderCost = {
          amount: 0,
          currency: "USD",
          display: "$0.00"
        };
        defaultCart.orderTax = { amount: 0, currency: "USD", display: "$0.00" };
        defaultCart.otherCharges = {
          amount: 0,
          currency: "USD",
          display: "$0.00"
        };
      }
      const totalCount = defaultCartResponse["total-quantity"];

      setCartDetails(prevState => ({
        ...prevState,
        cartDataFetched: true,
        defaultCart,
        totalCount,
        isCartUpdated: true
      }));
    }
  }

  function getCartDetails(
    createNewCart = false,
    defaultEmail = null,
    showPriceOnPickedTicket = false,
    disabledNotifications = null
  ) {
    return (
      cortexFetch(`/?zoom=${zoom.zoomFetchDefaultCartData.sort().join()}`)
        .then(res => checkResponse(res))
        // eslint-disable-next-line consistent-return
        .then(res => {
          if (
            res &&
            res._defaultcart &&
            res._defaultcart[0] &&
            !createNewCart
          ) {
            populateCartDetails(res);
          } else if (createNewCart) {
            return cortexFetch("/?zoom=defaultcart") // Will create a new default cart.
              .then(() => {
                return cortexFetch(
                  `/?zoom=${zoom.zoomFetchDefaultCartData.sort().join()}`
                );
              })
              .then(newCartDetails => checkResponse(newCartDetails))
              .then(newCartDetails => {
                if (
                  newCartDetails &&
                  newCartDetails._defaultcart &&
                  newCartDetails._defaultcart[0]
                ) {
                  populateCartDetails(newCartDetails);
                  if (
                    newCartDetails._defaultcart[0]._order &&
                    newCartDetails._defaultcart[0]._order[0]
                  ) {
                    const {
                      _cartorderdetails
                    } = newCartDetails._defaultcart[0]._order[0];
                    const cartOrderDetail = formatCartOrderDetails(
                      _cartorderdetails[0],
                      defaultEmail,
                      showPriceOnPickedTicket,
                      disabledNotifications
                    );
                    setOrderInformationData(cartOrderDetail.ordersInformation);
                  }
                }
              });
          }
        })
        .catch(err => {
          // If tokens are expired delegate session expired handling to
          // appheader.main fetchNavigationData
          if (!checkTokensExpired(err)) {
            console.error("getCartDetails => CartContext.js", err);
            setCartError(err.message);
          }
        })
    );
  }

  function populateCartList(response) {
    // If pricing or entitlement services fail on EP we are getting warning message in cart response (for every cart)
    // We will show "pending" instead of the price and allow user to add product to cart, but block the checkout flow
    const cartsElements = response._carts[0]._element.map(cart => {
      return checkCartWarning(cart);
    });
    response._carts[0]._element = cartsElements;
    setCartList(response._carts[0]);
  }

  function getSavedOrderCartList() {
    // Function to retrieve the list of all carts in saved orders for that user - and populate cartList state.
    return (
      cortexFetch(`/?zoom=${zoom.zoomCartList.sort().join()}`)
        .then(res => checkResponse(res))
        // eslint-disable-next-line consistent-return
        .then(res => {
          if (res && res._carts && res._carts[0]._element) {
            populateCartList(res);
          } else if (res && res._carts) {
            setCartList(null);
          }
        })
        .catch(err => {
          // If tokens are expired delegate session expired handling to component.
          if (!checkTokensExpired(err)) {
            console.error("getSavedOrderCartList => CartContext.js", err);
            setCartListError("Saved order cart list context error.");
          } else {
            throw err;
          }
        })
    );
  }

  function getCartList() {
    // Function to retrieve the list of all carts - and populate cartList state.
    return (
      cortexFetch(`/?zoom=${zoom.zoomCartList.sort().join()}`)
        .then(res => checkResponse(res))
        // eslint-disable-next-line consistent-return
        .then(res => {
          if (res && res._carts && res._carts[0]._element) {
            populateCartList(res);
          } else {
            return cortexFetch(`/carts/${scope}/default`) // Will create default cart first.
              .then(() => {
                return cortexFetch(`/?zoom=${zoom.zoomCartList.sort().join()}`);
              })
              .then(newCartDetails => checkResponse(newCartDetails))
              .then(newCartDetails => {
                if (
                  newCartDetails &&
                  newCartDetails._carts &&
                  newCartDetails._carts[0]._element
                ) {
                  populateCartList(newCartDetails);
                }
              });
          }
        })
        .catch(err => {
          // If tokens are expired delegate session expired handling to component.
          if (!checkTokensExpired(err)) {
            console.error("getCartList => CartContext.js", err);
            setCartListError("Cart list context error.");
          } else {
            throw err;
          }
        })
    );
  }

  // Set message in pop up under cart icon after successful add to cart
  function setSuccesCartPopupMessage(count) {
    setCurrentCount({
      type: "success",
      message: intl.get("item-was-added-to-your-cart", {
        count
      }),
      count,
      show: true
    });
    setTimeout(() => {
      setCurrentCount({ show: false });
    }, 3000);
  }

  // Set message in pop up under cart icon after failed add to cart
  function setErrorCartPopupMessage(message) {
    setCurrentCount({
      type: "error",
      message,
      show: true
    });
    setTimeout(() => {
      setCurrentCount({ show: false });
    }, 3000);
  }

  function setPayment(payment) {
    return setSelectedPayment(payment);
  }

  function setPaymentUri(uri) {
    return setSelectedPaymentUri(uri);
  }

  function setOrderDetails(details) {
    return setCartOrderDetails(details);
  }

  function setOrderInformationData(information) {
    return setOrderInformation(information);
  }

  function setClient(client) {
    return setSelectedClient(client);
  }

  function setNotificationStatus(status) {
    return setNotificationsStatus(status);
  }

  function setPaymentMethodError(error) {
    return setSelectedPaymentMethodError(error);
  }

  function setNotificationData(data) {
    return setNotificationsData(data);
  }

  function setCashUserError(error) {
    return setCashUserOnlyError(error);
  }

  function setDeliveryMethodError(error) {
    return setSelectedDeliveryMethodError(error);
  }

  function setShippingOptionsArray(options) {
    return setShippingOptions(options);
  }

  function setSelectedShipping(shippingOption) {
    return setSelectedShippingOption(shippingOption);
  }

  function setShippingAddressSelected(shippingAddres) {
    return setSelectedShippingAddress(shippingAddres);
  }

  function setShippingAddressesArray(addresses) {
    return setShippingAddresses(addresses);
  }

  function setPendingAddress(address) {
    return setPendingShippingAddress(address);
  }

  function setOrderInfoErrors(error) {
    return setOrderInformationErrors(error);
  }

  useEffect(() => {
    if (checkLogin && authToken && accountSharedId) {
      getCartDetails();
    }
  }, [checkLogin, authToken, accountSharedId]);

  return {
    currentCount,
    setCurrentCount,
    cartDetails,
    setCartDetails,
    getCartDetails,
    cartList,
    setCartList,
    getSavedOrderCartList,
    getCartList,
    cartError,
    cartListError,
    setSuccesCartPopupMessage,
    setErrorCartPopupMessage,
    checkCartWarning,
    cartDataFromAuth,
    setCartDataFromAuth,
    isCartFetchReady,
    setIsCartFetchReady,
    selectedCart,
    storeSelectedCart,
    setPayment,
    selectedPayment,
    setPaymentUri,
    selectedPaymentUri,
    setOrderDetails,
    cartOrderDetails,
    setOrderInformationData,
    orderInformation,
    setClient,
    selectedClient,
    setNotificationStatus,
    notificationsStatus,
    setPaymentMethodError,
    selectedPaymentMethodError,
    setNotificationData,
    notificationsData,
    setCashUserError,
    cashUserOnlyError,
    setDeliveryMethodError,
    selectedDeliveryMethodError,
    setShippingOptionsArray,
    shippingOptions,
    setSelectedShipping,
    selectedShippingOption,
    setShippingAddressSelected,
    selectedShippingAddress,
    setShippingAddressesArray,
    shippingAddresses,
    setPendingAddress,
    pendingShippingAddress,
    setOrderInfoErrors,
    orderInformationErrors
  };
};
